import { createGlobalStyle, css } from "styled-components";
import { hover, text } from "./globalStyleVars";

function createCSS() {
  let styles = "";

  for (let i = 2; i < 20; i += 1) {
    styles += `
        .anim-active.fade-up:nth-child(${i}) {
          transition-delay: ${i * 0.12}s;
        }
     `;
  }

  for (let a = 2; a < 100; a += 1) {
    styles += `
        .anim-active.fade-right span:nth-child(${a}) {
          transition-delay: ${a * 0.03}s;
        }
     `;
  }

  return css`
    ${styles}
  `;
}

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Symbols:wght@100..900&display=swap');

  ${createCSS()}
  #root {
    min-height: 100vh;
    overflow-x: hidden;
  }

  /* @font-face {
   font-family: 'Graphik';
   src: url('fonts/Graphik-Bold.woff2') format('font-woff2'),
   url('fonts/Graphik-Bold.woff') format('font-woff');
   font-weight: bold;
   font-style: normal;
   font-display: swap;
 } */


  

  @font-face {
    font-family:  "Suisse Int'l";
    src: url('/fonts/SuisseIntl-Semibold.woff2') format('woff2'),
    url('/fonts/SuisseIntl-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family:  "Suisse Int'l";
    src: url('/fonts/SuisseIntl-Regular.woff2') format('woff2'),
    url('/fonts/SuisseIntl-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family:  "Suisse Int'l";
    src: url('/fonts/SuisseIntl-Book.woff2') format('woff2'),
    url('/fonts/SuisseIntl-Book.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family:  "Suisse Int'l";
    src: url('/fonts/SuisseIntl-Medium.woff2') format('woff2'),
    url('/fonts/SuisseIntl-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family:  "Suisse Int'l";
    src: url('/fonts/SuisseIntl-Light.woff2') format('woff2'),
    url('/fonts/SuisseIntl-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }


  body {
    font-family: "Suisse Int'l", Arial, Helvetica, freesans, sans-serif !important;
    font-style: normal;
    margin: 0;
    color: ${text};
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    font-size: 16px;
    line-height: 24px;
    background: #F8F8F9;
    scrollbar-width: thin;
  }

  html {
    /* For Firefox */
    scrollbar-color: #CA0034 #F8F8F9;
    scrollbar-width: thin;
  }


  a {
    transition: color .3s ease;
    text-decoration: none;

    &:hover {
      color: ${hover} !important;
      text-decoration: none;
      outline: none;
      box-shadow: none;
    }

    &:focus {
      text-decoration: none;
      outline: none;
      box-shadow: none;
      color: ${text};
    }
  }

  ::selection {
    background: ${hover};
    color: #FFF;
  }

  //::-webkit-scrollbar {
  //  width: 5px;
  //  scrollbar-width: thin;
  //}
  //
  ///* Track */
  //::-webkit-scrollbar-track {
  //  background: #F8F8F9;
  //}
  //
  ///* Handle */
  //::-webkit-scrollbar-thumb {
  //  background: #ca0034;
  //  transition: 0.3s;
  //  height: 10px;
  //}
  //
  ///* Handle on hover */
 
  /* For Chrome and other browsers except Firefox */
  body::-webkit-scrollbar {
    width: 5px;
    background-color: #F8F8F9;
  }

  body::-webkit-scrollbar-thumb {
    background-color: #CA0034;
    transition: background-color 0.3s;
  }

  body::-webkit-scrollbar-thumb:hover {
    background-color: #2B3944;
  }

  .menu-showed {
    height: 100vh!important;
    overflow: hidden!important;
  }

  p, a, h4, h3, h5, h6 {
    color: ${text};
    font-weight: 400;
    margin: 0;
  }

  h1, h2 {
    margin: 0;
  }

  ul {
    margin: 0;
    padding: 0
  }

  li {
    list-style: none;
  }

  img {
    max-width: 100%;
    object-fit: contain;
  }

  h1 {
    font-size: 80px;
    line-height: 100px;
    letter-spacing: -4.8px;
    font-weight: 500;
    @media (max-width: 767px) {
      font-size: 40px;
      line-height: 40px;
      letter-spacing: -2.4px;
    }
  }

  h2 {
    font-size: 60px;
    font-weight: 500;
    letter-spacing: -3.6px;
    line-height: 72px;
    @media (max-width: 767px) {
      font-size: 36px;
      font-weight: 500;
      letter-spacing: -2.16px;
      line-height: 40px;
    }
  }

  h3 {
    font-size: 40px;
    font-weight: 400;
    line-height: 48px;
    @media (max-width: 767px) {
      font-size: 32px;
      font-weight: 400;
      line-height: 40px;
    }
  }

  h4 {
    font-size: 36px;
    font-weight: 400;
    line-height: 40px;
    @media (max-width: 767px) {
      font-size: 28px;
      font-weight: 400;
      line-height: 36px;
    }
  }

  h5 {
    font-size: 28px;
    font-weight: 500;
    line-height: 36px;
    @media (max-width: 767px) {
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
    }
  }

  h6 {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -1.2px;
    font-weight: 500;
  }

  span {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.24px;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }

  .meta-font {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
  }

  button {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.24px;
  }

  .btn:focus, button:focus, button:active:focus, .btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
    outline: none;
    box-shadow: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border-bottom: 1px solid #fff;
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
    transition: background-color 5000s ease-in-out 0s;
  }
 

  table {
    width: 100%;
  }

  form div {
    position: relative;
  }

  .form-control {
    box-shadow: none;
    outline: 0;
    border-radius: 0;

    &:focus {
      box-shadow: none;
    }
  }
  
  .facebook-bg {
    background-color: #1877F2;
  }

  .facebook-bg {
    background-color: #1877F2;
  }
  
  .twitter-bg {
   background-color:  #1DA1F2;
  }
  .instagram-bg {
    background: linear-gradient(to right, #833AB4, #E1306C);
  }
  
  .youtube-bg {
    background-color: #FF0000;
  }
  
  .linkedin-bg {
    background-color: #0077B5;
  }

  //.page-loader {
  //  position: fixed;
  //  height: 100%;
  //  width: 100%;
  //  top: 0;
  //  left: 0;
  //  background-color: #2B3944;
  //  z-index: 999999999;
  //}

  .page-loader  {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh; /* Height of the loading bar */
    background-color: black; /* Loader bar color */
    z-index: 999999999999;

    //
    //.container {
    //  position: absolute;
    //  left: 0;
    //  right: 0;
    //  bottom: 100px;
    //
    //}
  }
  
  //.slide-in {
  //  position: fixed;
  //  top: 0;
  //  left: 0;
  //  width: 100%;
  //  height: 100dvh;
  //  background: #0f0f0f;
  //  transform-origin: bottom;
  //  z-index: 9999;
  //}
  //
  //.slide-out {
  //  position: fixed;
  //  top: 0;
  //  left: 0;
  //  width: 100%;
  //  height: 100dvh;
  //  background: #0f0f0f;
  //  transform-origin: top;
  //  z-index: 9999;
  //}

  .form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 60px 0px;
    @media (max-width: 767px) {
      //padding: 60px 0px;
      padding-left: 15px;
      padding-right: 15px;
      

    }
    @media (min-width: 768px) and (max-width: 991px) {
      margin-bottom: 60px;
      margin-top: 60px;

    }

    .form-control {
      height: 50px;
      padding-left: 0;
      border: none;
      border-bottom: 1px solid rgba(248, 248, 249, 0.2);
      background-color: transparent;
      border-radius: 0px;
      font-size: 16px;
      color: #ffffff;
      margin-bottom: 30px;
    }

    ::placeholder {
      color: rgba(248, 248, 249, 0.5) !important;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
    }

    textarea {
      width: 100%;
      border: none;
      border-bottom: 1px solid #FFF;
      background-color: transparent;
      border-radius: 0px;
      font-size: 16px;
      max-height: 110px;
      min-height: 110px;
      min-width: 100%;
      max-width: 100%;
      outline: none;
      color: #ffffff;
      margin-top: 10px;
      margin-bottom: 40px;
    }

    .css-yk16xz-control {
      background: transparent;
      border: none;
      border-bottom: 1px solid #FFF !important;
    }

    .filter {
      &__placeholder {
        color: rgba(255, 255, 255, 0.5) !important;
        font-size: 16px;
        font-weight: 300;
      }

      &__control {
        padding-left: 0;
        padding-right: 0;

        &--is-focused {
          background-color: transparent !important;
          border: none;
          border-bottom: 1px solid #FFF;
        }
      }


      &__indicator {
        margin-right: 0;
        padding: 0;
      }

      &__value-container {
        padding-left: 0;
        padding-right: 0;
        cursor: pointer !important;
      }
    }

    .css-1uccc91-singleValue {
      color: #ffffff !important;
    }

    @media (max-width: 991px) {
      //padding: 0 !important;
    }

    @media (max-width: 575px) {
      .filter__control {
        margin-bottom: 25px !important;
      }
    }

    .form-right {
      position: relative;

      &__box {
        //margin-right: 40px;

        height: 40px;
        width: 175px;
        border: none;

        &:hover {
          border: none;
        }

        input {
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          border: 2px dashed #FFFFFF;
          border-radius: 26px;

          @media (max-width: 1200px) {
            width: 94%;
          }

          &:focus {
            outline: none;
            border: 2px dashed #FFFFFF;
          }

          &:after {
            background-image: url('/images/static/upload-svg.svg');
            content: 'Attach CV';
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            bottom: 0;
            transition: all 0.25s ease-in-out;
            background-color: #061524;
            //background-color: transparent;
            z-index: 2;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #FFFFFF;
            white-space: normal;
            text-align: center;
            cursor: pointer;
            background-repeat: no-repeat;
            background-position: 30px 50%;
            padding-left: 30px;
            transition-delay: 0.15s;

            @media (max-width: 1200px) {
              background-position: 20px 50%;
            }
          }

          @media (max-width: 767px) {
            width: 85%;
          }
        }

        @media (max-width: 767px) {
          margin-bottom: 40px;
          width: 100%;
        }
      }
    }

  }

  .p-0 {
    padding: 0 !important;
  }

  .pl-0 {
    padding-left: 0;
  }

  .pr-0 {
    padding-right: 0;
  }

  .pt-200 {
    padding-top: 200px;
    @media (max-width: 767px) {
      padding-top: 100px;
    }
  }

  .pb-200 {
    padding-bottom: 200px;
    @media (max-width: 767px) {
      padding-bottom: 100px;
    }
  }

  .pt-160 {
    padding-top: 160px;
    @media (max-width: 767px) {
      padding-top: 120px;
    }
  }

  .pb-160 {
    padding-bottom: 160px;
    @media (max-width: 767px) {
      padding-bottom: 120px;
    }
  }

  .pb-130 {
    padding-bottom: 130px;
    @media (max-width: 767px) {
      padding-bottom: 100px;
    }
  }

  .pt-120 {
    padding-top: 120px;
    @media (max-width: 767px) {
      padding-top: 60px;
    }
  }

  .pb-120 {
    padding-bottom: 120px;
    @media (max-width: 767px) {
      padding-bottom: 60px;
    }
  }

  .pt-100 {
    padding-top: 100px;
    @media (max-width: 767px) {
      padding-top: 60px;
    }
  }

  .pb-100 {
    padding-bottom: 100px;
    @media (max-width: 767px) {
      padding-bottom: 60px;
    }
  }

  .pt-80 {
    padding-top: 80px;
    @media (max-width: 767px) {
      padding-top: 40px;
    }
  }

  .pb-80 {
    padding-bottom: 80px;
    @media (max-width: 767px) {
      padding-bottom: 40px;
    }
  }


  .MuiDrawer-paper {
    width: 500px !important;
    padding: 20px;
    @media (max-width: 767px) {
      width: 100% !important;
    }
  }

  .swiper-button-disabled {
    opacity: 0 !important;
  }

  @media (min-width: 1500px) {
    .container {
      //min-width: 1366px;
      min-width: 85%;
      margin: auto;
    }
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    .container, .container-lg, .container-md, .container-sm {
      max-width: 90%;
      margin: auto;
    }
  }


  @media (max-width: 767px) {
    .container, .container-sm {
      max-width: 100%;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }


  //react select
  .css-yk16xz-control, .css-1pahdxg-control {
    height: 50px;
    border-radius: 0 !important;
    padding-left: 5px;
    font-size: 16px;
    outline: none !important;
    border-color: #D9D9D9 !important;
    box-shadow: none !important;

    .css-1wa3eu0-placeholder {
      font-weight: 300;
      line-height: 21px;
      color: rgba(0, 0, 0, 0.5);
      outline: none;
    }

    .css-1okebmr-indicatorSeparator {
      display: none;
    }

    .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
      margin-right: 10px;
    }
  }

  .css-2613qy-menu {
    border-radius: 0 !important;
    margin-top: 0 !important;
  }

  //.global-imagessss {
  //  &:before {
  //    content: '';
  //    position: absolute;
  //    inset: 0;
  //    height: 100%;
  //    width: 100%;
  //    z-index: 1;
  //    background: linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
  //  }
  //}
  
  .shadow-style {
      &:before {
        content: '';
        position: absolute;
        inset: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
      }
  }

  //animation class


  .info-window {
    max-width: 200px;
  }

  .gm-style-iw {
    border-radius: 0 !important;
  }

  .swiper-pagination-bullet {
    outline: none;
  }

  .css-nmuc1a-menu {
    z-index: 5 !important;
  }


  .map-info__img {
    img {
      height: 100px;
      margin-bottom: 12px;
      object-fit: cover;
    }
  }

  .map-info__content {
    h4 {
      font-size: 20px;
    }

    p {
      margin-bottom: 5px;
    }
  }

  .pointer {
    cursor: pointer;
  }

  .overlay {
    position: fixed;
    height: 100vh;
    width: 100%;
    //background-color: rgba(0,0,0,0.5);
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 9;
    display: none;

    &.show {
      display: block;
    }
  }

  .global-image-parallax {
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 1;
      background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
    }
  }

  .global-imagessss {
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 1;
      background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
    }
  }

  .form-control.has-error {
    border-color: #CA0034 !important;
  }

  .has-error .find-retainer-filter__control {
    border-color: #CA0034 !important;
  }

  //preloader
  .content-loader {
    position: absolute;
    height: 70%;
    width: 70%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .loading-before-submit {
    position: fixed;
    height: 100vh;
    width: 100%;
    bottom: 0;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.65);
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 40px;
    }
  }


  .swiper-slide {
    height: auto;
  }

  .slick-slide {
    div {
      outline: none !important
    }
  }

  button, button:active, button:focus, button:focus-visible {
    outline: none !important;
    box-shadow: none !important;
  }


  .hover {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      z-index: 2;
    }

    svg {
      position: relative;
      z-index: 2;
    }

    &:after {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      right: 0;
      top: 100%;
      margin: auto;
      background-color: ${text};
      transition: all .5s ease;
      border-radius: 50%;
    }

    &:hover {
      &:after {
        top: 0;
      }
    }
  }


  .modal-backdrop {
    background-color: rgb(34 31 31 / 90%);
    min-width: 100%;
    //z-index: 9999;

    &.show {
      opacity: 1;
    }
  }

  .modal_video_popup {
    .modal-video {
      background: transparent;
      position: absolute;
    }

    .modal-dialog {
      &:focus-visible {
        outline: none !important;
      }
    }

    .for-close {
      display: none;
    }

    .modal-dialog, .modal-content {
      background: transparent;
    }
  }


  .valid {
    color: ${hover};
    position: absolute;
    font-size: 12px;
    top: 44px;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }


  //menu fixed
  .scroll-down .main-menu {
    transform: translate3d(0, -100%, 0);
  }

  .scroll-down .main-menu__logo img {
    margin-top: unset !important;
  }

  .scroll-up .main-menu {
    background-color: rgb(249, 249, 249);
    height: 100px;

    .menu-desktop__hamburger__lines {
      &__inner .line {
        background-color: #FFF;
      }

      p {
        color: #FFF;
      }
    }

    .menu-desktop__hamburger .controller svg {
      path {
        fill: #191818;
      }

      line {
        stroke: #191818;
      }
    }

    .dc-btn a {
      color: #191818;

      &:hover {
        color: #191818 !important;
      }

      &:after, &:before {
        box-shadow: 0 0 0 1px #191818;
      }
    }
  }


  .form-control:disabled {
    background-color: transparent;
  }

  @media (max-width: 600px) {
    .prevent-overflow {
      overflow: hidden;
      height: 100vh;
    }
  }

  .Toastify__toast-container {
    z-index: 99999999;
  }

  .mobile-menu {
    #fb-root, .fb_reset {
      display: none !important;
      opacity: 0 !important;
      visibility: hidden !important;
    }
  }

  .slick-slide {
    -webkit-transform: translate3d(0, 0, 0);
  }

  .reveal, .revealFast {
    overflow: hidden;
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;

    img {
      transform-origin: left;
    }

    .global-image {
      background: transparent;
    }
  }

  //calender
  ._3efP_GeH5kyBAzqnLzL._kN_bCa3VNYpqFLH311L {
    border-radius: 0 !important;
  }

  //video modal 
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    background-color: ${hover};
  }

  
  
  .split-parent {
    overflow: hidden;
  }

  .split-child {
    overflow: hidden;
  }

  .reveal {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    img {
      transform-origin: left;
    }

    .global-image {
      background: transparent;
    }
  }


  //page transition
  .page-transition {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: ${hover};
    z-index: 999999999;
    display: none;
    opacity: 0;
    //display: flex;
    align-items: center;
    justify-content: center;

    .logo {
      height: 80px;
      opacity: 0;
    }
  }

  .next_swipper1 {
    svg {
      transform: rotate(180deg);
    }
  }

  .menu-visible {
    background-color: #F8F8F9 !important;

    &::before {
      background-color: transparent;
    }

    &:after {
      background-color: rgba(43, 57, 68, 0.5) !important;

    }

    .logo {
      img {
        display: none;

        &:nth-last-of-type(1) {
          display: block !important;
        }

      }
    }
  }


  #smooth-content {
    will-change: transform;
  }

  .modal-backdrop {
    background-color: rgba(0, 0, 0, 0.4);
  }

  .modal.show .modal-dialog {
    transform: none;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
  }

  .modal-dialog {
    max-width: 768px;
    //margin: 1.75rem auto;
  }

  .modal-content {
    background-color: #2B3944;
    border: unset;
  }

  .modal-body {
    padding: 0;
    margin-top: 80px;
  }

  .modal-close {
    cursor: pointer;
    padding-right: 60px;
    padding-top: 20px;
  }

  .for-close {
    display: flex;
    align-items: center;
    justify-content: end;
  }

  .profile-info {
    &__text {
      padding-top: 20px;

      p {
        color: #F8F8F9 !important;
      }
    }
  }

  .profile-details {
    padding-top: 40px;

    p {
      color: #F8F8F9;
    }
  }

  .modal-main-content {
    height: calc(100vh - 80px);
    overflow-y: scroll;
    padding-left: 70px !important;
    padding-right: 70px !important;
    /* small mobile :320px. */

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media (max-width: 767px) {
    .modal-main-content {
      padding-left: 30px !important;
      padding-right: 30px !important;
      margin-top: 20px;
    }

    .modal-close {
      padding-right: 30px;
    }

    .modal.show .modal-dialog {
      margin-left: unset;
      padding-left: unset;
    }

    body::-webkit-scrollbar {
      width: unset;
    }
    body {
      scroll-behavior: unset;
    }
  }

  .global-icon {
    position: fixed;
    content: "";
    right: 30px;
    z-index: 999;
    bottom: 60px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* small mobile :320px. */
    @media (max-width: 767px) {
      right: 15px;
      bottom: 40px;
    }
  }

  .title {
    overflow: hidden;
  }

  //.loader{
  //  position: fixed; /* Use fixed instead of absolute for viewport centering */
  //  width: 100%;
  //  height: 100%;
  //  top: 0;
  //  left: 0;
  //  background: rgba(0, 0, 0, 0.5); /* Adjust the alpha channel for transparency */
  //  display: flex;
  //  justify-content: center;
  //  align-items: center;
  //  z-index: 999;
  //  .spinner {
  //    border: 4px solid rgb(202, 0, 52);
  //    border-radius: 50%;
  //    border-top: 4px solid rgb(248, 248, 249);
  //    width: 60px;
  //    height: 60px;
  //    animation: spin 1s linear infinite;
  //  }
  //
  //  @keyframes spin {
  //    0% { transform: rotate(0deg); }
  //    100% { transform: rotate(360deg); }
  //  }
  //}

.modal-backdrop{
  background-color: rgba(34, 31, 31, 0.9);
  min-width: 100%;
  z-index: 99999;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
}
.modal-open .modal {
    //-ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: thin; /* Firefox */
  }
  
  .popup-version-one{
    height: 100vh;
    //scrollbar-width: none;
    z-index: 99999;
    //overflow: hidden !important;
    width: 638px;
    right: 0;
    left: auto;
    
    @media(max-width: 767px){
      width: 100%;
    }
    
    //padding: 0px 70px 30px 70px !important;
    .modal-dialog{
      height: 100vh;
      background-color: transparent;
      min-width: 100%;
      margin: 0;
      .modal-content{
        //background-color: rgb(0 0 0 / 40%) !important;
        background-color: transparent;
        //margin: 100px 0;
        .main_scroll{
          //width: 638px;
          background-color: rgb(43, 57, 68);
          //position: absolute;
          //right: 0;
          //height: 100vh;
          //margin: 100px 0;
          .modal-body{
            margin-top: 0 !important;
            height: 100vh;
            .modal-main-content{
              height: auto !important;
              overflow: hidden;
              padding-bottom: 80px !important;
              background: rgb(43, 57, 68);
              
              .profile-info{
                .img-wrp{
                  //position: relative;
                  //padding-top: calc(330 / 270 * 100%) !important;
                  //min-height: 330px !important;
                  max-height: 330px;
                  width: 270px;
                  overflow: hidden;
                  img{
                    height: 100%;
                  }
                  //img{
                  //  position: absolute;
                  //  top: 0;
                  //  left: 0;
                  //  width: 100%;
                  //  height: 100%;
                  //  object-fit: cover;
                  //}
                }
              }
              .profile-details-container {
                
              }

              .profile-details {
                
              }
            }
          }
        }
      }
    }
  }

  /* Tablet desktop :768px. */
  @media (min-width: 768px) and (max-width: 991px) {
    .main_scroll{ 
      width: unset!important;
    }
  }


  /* small mobile :320px. */
  @media (max-width: 767px) {
    .main_scroll{
      width: unset!important;
    }
  }
  .no-scroll{
    overflow: hidden !important;
  }
  .css-151sz6c-indicatorContainer {
    padding: 0 !important;
    margin-right: 25px !important;
  }

  .faker{
    height: 100px;
    @media(max-width: 767px){
      display: none;
    }
  }
`;
