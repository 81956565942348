import React from 'react';
import styled from "styled-components";
import { Container, Row, Col} from "react-bootstrap";
import Subtitle from "./Subtitle";
import reactHtmlParser from "react-html-parser";

// Internal Component
const ListsV1 = ({data}) => {
    console.log()
    return (
        <StyledListsV1 className={'pt-160 pb-160'} >
            <Container>
                <Row>
                    <Col sm={{span: 10, offset: 1}} >
                        <Subtitle text={'Safety <span>Guidance</span>'} />
                        <div className={'safety-list'}>
                            {reactHtmlParser(data?.data?.description)}
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledListsV1>
    );
};

const StyledListsV1 = styled.div`
  .safety-list ul li {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid rgba(43, 57, 68, 0.2);
    padding: 30px 0px;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }

  //padding-bottom: 160px;
  .safety-list ul li:first-child {
    border-bottom: 1px solid #2B3944;
    padding-bottom: 30px;
    padding-top: 60px;
  }

  .safety-list ul li:first-child p {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -1.2px;
    line-height: 28px;
    color: #2B3944;
  }

  .safety-list ul li:not(:first-child) p {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #2B3944;
  }

  .safety-list ul li:nth-last-of-type(1) {
    border-bottom: unset;
  }

  .safety-list ul li p {
    flex: 0 0 calc(50% - 35px);
    font-size: 16px;
    font-weight: 400;
    color: rgb(79, 97, 107);
    overflow: hidden;
  }


`;

export default React.memo(ListsV1);
