import React  from "react";
import styled from "styled-components";
import {Accordion} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";
import {BsChevronDown} from "react-icons/bs";

const AccordionItem = ({titleColor, accordion}) => {
    return (
        <StyledComponent titleColor={titleColor} >
            <Accordion defaultActiveKey="0">
                {
                    accordion?.map((item, index) => {
                        return (
                            <Accordion.Item key={index} eventKey={`${index}`}>
                                <Accordion.Header>
                                    {reactHtmlParser(item?.data?.title)}
                                    <span>
                              <BsChevronDown/>
                            </span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p>
                                        {reactHtmlParser(item?.data?.description)}
                                    </p>
                                </Accordion.Body>
                            </Accordion.Item>
                        )
                    })
                }
            </Accordion>
        </StyledComponent>
    );
};
const StyledComponent = styled.section`
  .accordion-item {
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }

  h2 {
    font-size: unset;
    line-height: unset;
    font-weight: unset;
    letter-spacing: unset;
  }


  .accordion-item:nth-of-type(1) h2 button {
    padding-top: unset;
  }

  .accordion-header {
    position: relative;
    margin: 0;

    button {
      border: none;
      background-color: transparent;
      padding-left: 0;
      color: #F8F8F9;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      text-transform: capitalize;
      padding-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #F8F8F9;
      width: 100%;
      text-align: left;
      transition: all 0.4s ease;

      span {
        color: #CA0034 !important;
        background-color: #F8F8F9;
      }

      &.collapsed {
        color: #F8F8F9;
        border-color: rgba(248, 248, 249, 0.2);
        background: transparent;

        span {
          background-color: transparent;
          color: #F8F8F9 !important;

          svg {
            transform: rotate(0deg);
          }

          &:after {
            background-color: #F8F8F9;
          }

        }
      }
    }

    span {
      position: absolute;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      border: 1px solid #F8F8F9;
      color: #F8F8F9;
      background-color: #FFFFFF;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 16px;

      &:after {
        height: 0;
        width: 0;
        background-color: #F8F8F9;
        border-radius: 50%;
        opacity: 0;
        transition: all 0.4s ease;
        content: "";
        position: absolute;

        svg {
          color: #F8F8F9 !important;
        }
      }

      svg {
        font-size: 10px;
        z-index: 2;
        transform: rotate(180deg);
      }
    }

    &:hover {
      span {
        &:after {
          height: 100%;
          width: 100%;
          opacity: 1;
        }
        svg {
          color: #CA0034;
        }
      }

      button {
        color: #F8F8F9;
        border-color: rgba(193, 193, 193, 0.5);
      }
    }
  }

  .accordion-body {
    padding-top: 20px;
    padding-bottom: 20px;

    p {
      font-size: 16px;
      font-weight: 400 !important;
      line-height: 24px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }

    .custom-list {
      padding: 0;

      li {
        position: relative;
        padding-left: 20px;
        //padding-top: 20px;
        &:not(:last-child) {
          padding-bottom: 0px;
        }

        &:before {
          content: "";
          position: absolute;
          top: 12px;
          left: 0;
          margin-top: -5px;
          width: 10px;
          height: 10px;
          box-sizing: content-box;
          background-color: #c1c1c1;
        }
      }
    }

    h4 {
      font-size: 16px;
      line-height: 22px;
      color: rgba(34, 31, 31, 0.2);
      margin: 0 0 10px 0;
    }

    p,
    a {
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      color: #F8F8F9;
    }

    .contact-numbers {
      margin-top: 45px;

      ul {
        li {
          a {
            margin-bottom: 13px;
            display: flex;

            span {
              font-size: 26px;
              font-weight: bold;
              line-height: 32px;
              transition: 0.3s ease;
            }

            &:hover span {
              color: #F8F8F9;
            }
          }

          &:nth-last-of-type(1) {
            margin-bottom: 0;
          }
        }
      }
    }

    .map {
      margin-top: 45px;

      iframe {
        width: 100%;
        height: 350px;
      }
    }
  }

  .address__img {
    position: relative;
    padding-top: calc(530 / 530 * 100%);
  }

  @media (max-width: 768px) {
    .address__accordion {
      min-width: 100%;
    }

    .address__form {
      min-width: 100%;
      margin-top: 60px;
    }
  }

  @media (max-width: 767px) {
    //
    //.address__row {
    //  flex-direction: column-reverse;
    //}
    .address__accordion {
      margin-top: 60px;
    }

    .address__form {
      margin-top: 0;
      margin-bottom: 60px;
    }

    .contact-numbers {
      flex-wrap: wrap;

      .col-sm-4 {
        min-width: 50%;

        &:nth-last-child(1) {
          margin-top: 30px;
          padding-left: 0;
        }
      }
    }
  }
`;
export default React.memo(AccordionItem);
