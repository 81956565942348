import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {
    return (
        <StyledComponent className={'pb-160'} >
            <Container>
                <Row>
                    <Col>
                        {
                            data?.subtitle &&
                            <h4 className={'split-up'}>{reactHtmlParser(data?.subtitle)}</h4>
                        }
                        {
                            data?.description &&
                            <p className={'split-up'}>{reactHtmlParser(data?.description)}</p>
                        }
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  h4 {
    font-size: 60px;
    line-height: 72px;
    font-weight: 600;
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    color: #2B3582;
    span {
      color: #CA0034;
      font-size: 60px;
      line-height: 72px;
      font-weight: 600;
      //margin-bottom: 30px;
      display: flex;
      flex-wrap: wrap;
      margin-left: 20px;
    }
  }

  p {
    display: flex;
    flex-wrap: wrap;

    &:not(:nth-last-of-type(1)) {
      margin-bottom: 24px;
    }
  }

  .mission-vision {
    margin-top: 80px;

    h3 {
      font-size: 20px;
      line-height: 28px;
      font-weight: 500;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 768px) {
    .col-sm-9 {
      min-width: 100%;
    }
  }

  @media (max-width: 767px) {
    h4 {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 40px;
      span {
        font-size: 32px;
        line-height: 40px;
        margin-bottom: unset;
        margin-left: 10px;
      }
    }

    h4 p {
      font-size: 30px !important;
      line-height: 30px !important;

    
    }

    .mission-vision {
      margin-top: 40px;

      p {
        margin-bottom: 40px;
      }
    }
  }
`;

export default MyComponent;
