import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";
import {ImageParallax} from "./ImageParallax";

const MyComponent = ({offset, data, dataSpeed}) => {
    const zigZagData = data?.posts?.list;
    return (
        <StyledComponent className={'pt-160 pb-160'} offset={offset}>
            <div className="zigzag-area">
                <div className="zigzag-area__single">
                    <Container fluid p-0>
                        {
                            zigZagData?.map((item) => {
                                return (
                                    <Row>
                                        <Col className={'p-0  zigzag-area__single__wrap'} md={5}>
                                            <div className="zigzag-area__single__wrap__text">
                                                <span>{item?.data?.subtitle}</span>
                                                <h4>{reactHtmlParser(item?.data?.short_desc)}</h4>
                                                <p>{reactHtmlParser(item?.data?.description)}
                                                </p>
                                            </div>
                                        </Col>

                                        <Col className={'p-0'} md={{span: 6, offset: 1}}>
                                            <div className="zigzag-area__single__img">
                                                <ImageParallax src={item?.images?.[0]?.full_path}/>
                                            </div>
                                        </Col>
                                    </Row>
                                )
                            })
                        }

                    </Container>
                </div>

            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  .zigzag-area {
    &__single {
      &__wrap {
        display: flex;
        align-items: center;

        &__text {
          padding-left: ${props => props.offset ? props.offset : ''}px;

          span {
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            color: #9CABB9;
          }

          h4 {
            margin-top: 20px;
            margin-bottom: 40px;
            overflow: hidden;

            span {
              color: #CA0034;
              font-size: 36px;
              font-weight: 400;
              line-height: 40px;
              overflow: hidden;
            }
          }

          p {
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
          }
        }
      }

      &__img {
        position: relative;
        padding-top: calc(560 / 668 * 100%);
      }
    }
  }

  .row:nth-child(even) {
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 160px 0;

    .offset-md-1 {
      margin-left: unset;
    }

    .zigzag-area {
      &__single {
        &__wrap {
          &__text {
            padding-left: unset;
            padding-right: ${props => props.offset ? props.offset : ''}px;
          }
        }

        &__img {
          position: relative;
          padding-top: calc(560 / 668 * 100%);
          margin-right: 30px;
        }
      }
    }
  }

  /* small mobile :320px. */
  @media (max-width: 767px) {
    .row:nth-child(odd) {
      flex-direction: column-reverse;

      .zigzag-area__single__wrap__text {
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 40px;
      }
    }

    .row:nth-child(even) {
      flex-direction: column-reverse;
      padding: 60px 0;

      .zigzag-area__single__wrap__text {
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 40px;
      }
    }

    .row:nth-child(2n) .zigzag-area__single__img {
      margin-right: 0px;
    }
  }
`;

export default React.memo(MyComponent);
