import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "./Img";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({offset, data}) => {
    return (
        <StyledComponent offset={offset}>
            <div className="plan-area">

                <Container fluid className={'p-0'}>
                    <Row>
                        <Col sm={6}>
                            <div className="plan-area__img">
                                <Img reveal src={ data?.images?.[0]?.full_path ? data?.images?.[0]?.full_path : '/images/dynamic/plan.jpg'}/>
                            </div>
                        </Col>
                        <Col className={'plan-area__content'} sm={{span: 5, offset: 1}}>
                            <div className="plan-area__content__wrap">
                                <span className={'split-up'} >{data?.data?.title ? data?.data?.title : 'Project Procedure'}</span>
                                <h4 className={'split-up'} >{data?.data?.title ? data?.data?.subtitle : 'Delivering excellence every step of the way'}</h4>
                                {/*<p>{reactHtmlParser(data?.data?.description)}</p>*/}
                                <div className={'description'} >{reactHtmlParser(data?.data?.description)}</div>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background: #2B3944;

  .plan-area {
    &__img {
      position: relative;
      padding-top: calc(560 / 668 * 100%);
    }

    &__content {
      display: flex;
      align-items: center;

      &__wrap {
        padding-right: ${props => props.offset ? props.offset : ''}px;

        span {
          color: #F8F8F9;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
        }

        h4 {
          color: #F8F8F9;
          padding-bottom: 40px;
          padding-top: 20px;
        }

        p {
          color: #F8F8F9;
        }
        .description {
          color: #F8F8F9;
          ul {
            display: flex;
            flex-direction: column;
            gap: 5px;
            li {
              list-style: disc;
            }
          }
        }
      }
    }
  }

  /* small mobile :320px. */
  @media (max-width: 767px) {
    .plan-area {
      &__content {

        &__wrap {
          padding-top: 40px;
          padding-right: 15px;
          padding-left: 15px;
        }
      }
    }

  }
`;

export default React.memo(MyComponent);
