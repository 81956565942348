import React from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from "react-bootstrap";
import Button from "../Button";
import {Img} from "../Img";
import {ImageParallax} from "../ImageParallax";
import reactHtmlParser from "react-html-parser";


const Cta = ({bigImg, mobileImg, title, buttonText, pb, redirect}) => {
    return (
        <StyledCTA pb={pb} className={''}>
            <div className="image-wrapper">
                {
                    window.innerWidth > 991 ? <ImageParallax dataSpeed={'0.6'} alt={title} src={bigImg}/> : <Img src={mobileImg}/>
                }
                <Container>
                    <Row>
                        <Col lg={6} md={12} >
                            <div className="content">
                                <h2>{reactHtmlParser(title)}</h2>
                                <Button hoverBackground={'#CA0034'} hoverBorderColor={'#CA0034'} data-lag={0.1}
                                        background={'transparent'} border={'1px solid #F8F8F9'} color={'#F8F8F9'}
                                        src={redirect}
                                        text={buttonText}/>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </StyledCTA>

    )
};


const StyledCTA = styled.div`
  position: relative;
  overflow: hidden;

  .container {
    position: relative!important;
    z-index: 2;
  }

  .image-wrapper {
    padding-bottom: 160px;
    position: relative;
    padding-top: 160px;

    @media (min-width: 1920px) {
      padding-top: 250px;
      padding-bottom: 250px;
    }
  }

  h2 {
    color: #F8F8F9;
    padding-bottom: 40px;
    display: flex;
    flex-wrap: wrap;
  }

  /* Normal desktop :1200px. */
  @media (min-width: 1200px) and (max-width: 1500px) {
    .container {
      padding-left: 100px;
    }
  }

  /* Tablet desktop :768px. */
  @media (min-width: 768px) and (max-width: 991px) {
    h2 {
      font-size: 36px;
      font-weight: 500;
      letter-spacing: -2.16px;
      line-height: 40px;
    }
  }


  @media (max-width: 767px) {
    padding-bottom: ${props => props.pb ? props.pb : ''} !important;

    .image-wrapper {
      padding-top: 80px;
      padding-bottom: 80px;
    }

    h2 {
      padding-bottom: 40px;
    }

    .content {
      padding: 0px 15px;
    }
  }
`;

export default React.memo(Cta);














