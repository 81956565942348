import React, {useState} from 'react';
import styled from 'styled-components';
import ModalVideo from "react-modal-video";
import 'react-modal-video/css/modal-video.min.css'
import Call from "./Call";
import {Img} from "./Img";
import {ParallaxBg} from "./ParallaxBg";

const playIcon = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="32" viewBox="0 0 24 32">
    <path id="Polygon_2" data-name="Polygon 2" d="M13.5,3.744a3,3,0,0,1,4.992,0L28.891,19.336A3,3,0,0,1,26.394,24H5.606a3,3,0,0,1-2.5-4.664Z" transform="translate(24) rotate(90)" fill="#f8f8f9"/>
</svg>


const VideoModal = ({video}) => {
    let [open, setOpen] = useState(false);
    let [videoId, setVideo] = useState('');

    let handelOpen = (open, id) => {
        setOpen(open);
        setVideo(id);
    };

    const handleCloseModal = () => {
        setOpen(false);
        setVideo('');
    };
    return (

        <StyledVideo data-scroll-container
                     className={`video_body`}>
            <ModalVideo
                channel="youtube"
                isOpen={open}
                videoId={videoId}
                onClose={handleCloseModal}
                autoplay // Add the autoplay prop to enable video autoplay
            />

            <div onClick={() => handelOpen(true, video?.short_title ? video?.short_title : 'gRmsZkk1fo')} className="image_video_wrap">
                <Img  src={video?.full_path ? video?.full_path : '/images/dynamic/video-popup.jpg'} alt={video?.short_title} />
                <div className="play" data-speed={1.2}>
                    <Call icon={playIcon} height={80} width={80} />
                </div>
            </div>
        </StyledVideo>

    )
};


const StyledVideo = styled.div`
  .container {
    padding-left: ${props => props.offset ? props.offset + 15 + 'px' : ''};
    position: relative;
    z-index: 1;
    @media (max-width: 767px) {
      padding-left: 15px !important;
      padding-right: 15px !important;
      .col-md-12 {
        padding: 0;
      }
    }
  }
  

  .image_video_wrap {
    padding-top: calc(620 / 668 * 100%);
    cursor: pointer;
    overflow: hidden;
    position: relative;

    .play {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      height: 100px;
      width: 100px;
      border-radius: 50%;
      @media (max-width: 767px) {
        height: 50px;
        width: 50px;
        svg {
          height: 24px;
          width: 32px;
        }
      }

      &:before {
        content: '';
        position: absolute;
        border: none;
        background: rgba(86, 84, 64, 0.5);
        border-radius: 50%;
        opacity: 0;
        transition: 0.6s all cubic-bezier(0.4, 0, 0, 1);
        z-index: -4;
      }

      &:after {
        content: "";
        z-index: 0;
        //background-color: rgba(30, 92, 149, 0.5);
        overflow: hidden;
        border-radius: 50%;
        transition: 0.6s all cubic-bezier(0.4, 0, 0, 1);
      }

      svg {
        #Ellipse_396, path {
          transition: 0.7s all ease;
        }
      }
    }

    .global-image {
      img {
        transition: 0.7s all cubic-bezier(0.4, 0, 0, 1);
        transform: scale(1.01);
      }
    }

    @media (max-width: 767px) {
      padding-top: calc(350 / 375 * 100%);
    }

    &:hover {
      .global-image {
        img {
          transform: scale(1.04);

        }

      }

      .play {
        &:before {
          animation: cubic-bezier(0.4, 0, 0, 1) 1;
          animation-duration: 0.9s;
          animation-direction: alternate-reverse;
          animation-iteration-count: infinite;
        }
        .navigation li.hover::after {
          top: 0px;
        }

        &:after {
          opacity: 0;
        }

        svg {
          #Ellipse_396 {
            r: 50;
          }

          path {
            fill: white;
          }
        }

      }
    }

  }

  .modal-video-movie-wrap {
    bottom: -170px;
  }

  /* Tablet desktop :768px. */
  @media (min-width: 768px) and (max-width: 991px) {

    .modal-video-movie-wrap {
      bottom: -470px;
    }
  }

`;


export default React.memo(VideoModal);














