import React from 'react';
import styled from "styled-components";

const SingleAddress = ({name, address, phone, mapLink, district, division}) => {
    return (
        <StyledComponent>
            <div className="content">
                <div className="content__title">
                    <p>{name}</p>
                    {
                        mapLink && <a target={"_blank"} href={mapLink} className="content__title__icon">
                            <p>Google Map</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                <g id="Ellipse_633" data-name="Ellipse 633" fill="none" stroke="#ca0034" stroke-width="1">
                                    <circle cx="10" cy="10" r="10" stroke="none"/>
                                    <circle cx="10" cy="10" r="9.5" fill="none"/>
                                </g>
                                <path id="Path_8282" data-name="Path 8282"
                                      d="M126.434,1.6a3.492,3.492,0,0,0-.5-.626,3.413,3.413,0,0,0-4.77,0,3.492,3.492,0,0,0-.5.626,3.771,3.771,0,0,0-.15,3.372,8.894,8.894,0,0,0,2.02,3.06,6.793,6.793,0,0,0,1.016.851,6.805,6.805,0,0,0,1.016-.851,8.9,8.9,0,0,0,2.02-3.06,3.774,3.774,0,0,0-.15-3.372m-3.989,3.084a1.56,1.56,0,1,1,2.206,0,1.56,1.56,0,0,1-2.206,0"
                                      transform="translate(-113.548 5.56)" fill="#ca0034" fill-rule="evenodd"/>
                            </svg>

                        </a>
                    }

                </div>
                <div className="content__title__address">
                    {address && <a target={"_blank"} href={mapLink}>{address}</a>}
                </div>
            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.div`
  background-color: #FFFFFF;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 10px 20px;
  padding: 20px;
  margin-bottom: 30px;
  min-height: 200px;
  transition: all 0.5s ease-in-out;

  .content {
    min-height: 180px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    &__title {
      display: flex;
      justify-content: space-between;

      p {
        color: #2B3944;
        margin-right: 10px;
      }

      &__icon {
        display: flex;
        gap: 10px;
        align-items: center;

        p {
          color: #CA0034;
        }
      }

      &__address {
        display: flex;
        flex-direction: column;

        a:nth-last-of-type(2) {
          padding-bottom: 20px;
          color: #9CABB9;
          margin-right: 130px;
          width: fit-content;
        }

        a:nth-last-of-type(1) {
          color: #9CABB9;
          width: fit-content;
        }
      }
    }
  }

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 20px 40px;
    transform: scale(1.03);
    transition: all 0.5s ease-in-out;
  }
`;

export default React.memo(SingleAddress);
