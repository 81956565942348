import React from 'react';
import styled from "styled-components";
import {hover} from "../styles/globalStyleVars";

const Call = ({icon, backgroundColor, hoverBackgroundColor, height, width, borderColor, hoverBorderColor, url, shadow, link}) => {
    return (
        <StyledComponent shadow={shadow} hoverBorderColor={hoverBorderColor} borderColor={borderColor} height={height} width={width}
                         backgroundColor={backgroundColor} hoverBackgroundColor={hoverBackgroundColor}>
            <a className="navigation" href={url} target={"_blank"}>
                <li className={"hover"}>
                    {icon}
                </li>
            </a>
        </StyledComponent>
    );
};

const StyledComponent = styled.div`
  width: ${props => props.width ? props.width : '37'}px;
  height: ${props => props.height ? props.height : '37'}px;
  box-shadow: ${props => props.shadow ? props.shadow : ''};
  border-radius: 50%;

  .swiper-button-disabled {
    opacity: 1 !important;
  }

  ul.navigation {
    padding: 0;
  }

  .navigation {
    display: flex;
    align-items: center;

    li {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
      background: ${props => props.backgroundColor ? props.backgroundColor : '#CA0034'};
      border: ${props => props.borderColor ? props.borderColor : ''};

      svg {
        line {
          transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
        }

      }

      &.hover:after {
        background-color: ${props => props.hoverBackgroundColor ? props.hoverBackgroundColor : '#2B3944'};;
      }


      position: relative;
      border-radius: 50%;
      height: ${props => props.height ? props.height : '37'}px;
      width: ${props => props.width ? props.width : '37'}px;

      svg {
        position: relative;

        #Ellipse_4378 {
          transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
          r: 0;
        }

        &:hover {
          #Ellipse_4378 {
            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
            r: 26px;
          }
        }
      }
    }
  }

  .navigation li:hover {
    border-color: ${props => props.hoverBorderColor ? props.hoverBorderColor : ''};;
  }

`;

export default React.memo(Call);
