import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Modal from "react-bootstrap/Modal";
import {Col, Container, Row} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";
import Call from "./Call";
import {Img} from "./Img";
import {RemoveScroll} from "react-remove-scroll";

const PeoplePopup = ({
                         show,
                         handleClose,
                         no_img,
                         item,
                         data,
                         subtitle,
                         img,
                         title,
                         des,
                         description
                     }) => {


    const popupData = data;
    const [winWidth, setWinWidth] = useState(true)
    useEffect(() => {
        if (window.innerWidth > 767) {
            setWinWidth(true)
        } else {
            setWinWidth(false)
        }
        window.addEventListener("resize", () => {
            if (window.innerWidth > 767) {
                setWinWidth(true)
            } else {
                setWinWidth(false)
            }
        });
    }, [])

    const close = <svg xmlns="http://www.w3.org/2000/svg" width="8.485" height="8.486" viewBox="0 0 8.485 8.486">
        <line id="Line_11" data-name="Line 11" x2="10" transform="translate(0.707 0.707) rotate(45)" fill="none"
              stroke="#f8f8f9" stroke-linecap="round" stroke-width="1"/>
        <line id="Line_12404" data-name="Line 12404" x2="10" transform="translate(7.778 0.707) rotate(135)" fill="none"
              stroke="#f8f8f9" stroke-linecap="round" stroke-width="1"/>
    </svg>


    return (

        <StyledModal>
            <Modal
                show={show}
                item={item}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="gph_modal popup-version-one"
            >
                <div className="main_scroll">
                    <Modal.Body>
                        <Container>
                            <Row className={'for-close'}>
                                <div onClick={handleClose} className="modal-close ">
                                    <Call hoverBorderColor={"#CA0034"} borderColor={'1px solid #F8F8F9'} icon={close}
                                          backgroundColor={'transparent'}
                                          hoverBackgroundColor={'#CA0034'}/>
                                </div>
                            </Row>
                        </Container>
                        <Container>
                            <Row>
                                <div className="modal-data d-flex">
                                    <Col sm={12} className="modal-main-content p-0">
                                        <div className="profile-info">
                                            <div className="img-wrp">
                                                <img
                                                    src={img ? img : 'images/static/blur.jpg'}/>
                                            </div>
                                            <div className="profile-info__text">
                                                <p>{title}</p>
                                                <p>{des}</p>
                                            </div>
                                        </div>
                                        <div className="profile-details-container">
                                            <div className="profile-details">
                                                <p>{reactHtmlParser(description)}</p>
                                            </div>
                                        </div>
                                    </Col>
                                </div>
                            </Row>
                        </Container>
                    </Modal.Body>
                </div>
            </Modal>

        </StyledModal>

    )
};


const StyledModal = styled.div`
  .modal-dialog {
    //margin: 0;
  }
 
  
  //h4 {
  //  letter-spacing: 1.5px;
  //}

`;


export default React.memo(PeoplePopup);
