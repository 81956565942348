import React, {useEffect} from 'react';
import styled from "styled-components";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam} from "../../api/network/apiParams";
import {fetchMediaData, fetchMediaDetail} from "../../api/redux/media";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import InsightSlider from "../../components/home/InsightSlider";
import MediaDetails from "../../components/media/MediaDetails";
import {motion} from "framer-motion";
import {PageAnimation} from "../../components/PageAnimation";
import {HelmetProvider, Helmet} from "react-helmet-async";

const MyComponent = () => {

    const dispath = useDispatch()
    let {slug} = useParams();

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.CHILD_PAGE;

        let api_params = {
            [ApiParam.PAGE_ID]: "15",
            [ApiParam.VALUE]: slug,
            [ApiParam.GET_SECTION]: "yes",
            [ApiParam.IMAGE]: "yes",
            [ApiParam.POST]: "yes",
            [ApiParam.FILE]: "yes",
            [ApiParam.GALLERY]: "yes",
        };

        let blog_url = apiEndPoints.SECTIONS;

        let blog_params = {
            [ApiParam.TYPE]: "slug",
            [ApiParam.VALUE]: "media-center",
            [ApiParam.GET_SECTION]: "yes",
            [ApiParam.IMAGE]: "yes",
            [ApiParam.POST]: "yes",
        };

        dispath(fetchMediaData([blog_url, blog_params]));

        dispath(fetchMediaDetail([api_url, api_params]));
    }, [PageAnimation]);
    // const router = useRouter();
    const getData = useSelector(store => store.media)

    const data = getData?.detail?.data?.list?.find((f) => f?.page_data?.slug === slug);
    const introduction = data?.posts?.list?.find((f) => f?.data?.slug === "introduction");
    const description = data?.posts?.list?.find((f) => f?.data?.slug === "description");
    const video = data?.posts?.list?.find((f) => f?.data?.slug === "video");
    const images = data?.posts?.list?.find((f) => f?.data?.slug === "images");

    const type = data?.page_data?.type;
    const all = getData?.posts?.media_center?.list?.filter((f) =>
        f?.page_data?.type === type &&
        f?.page_data?.slug !== data?.page_data?.slug
    ) || [];

    const id = video?.data?.subtitle;

    return (
        <HelmetProvider>
            <motion.div key={`689`} className="page-loader" exit="exit" animate="anim"
                        variants={PageAnimation}
                        initial="init">
            </motion.div>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Omera Gas One | Media Center</title>
                <meta name="Discover the latest news, updates, and multimedia resources in our dynamic Media Center"
                      content="Stay informed with Omera"/>
            </Helmet>
            <StyledComponent>
                <MediaDetails data={data} introduction={introduction} description={description} video={video}
                              images={images} id={id}/>
                {all?.length > 0 &&
                    <InsightSlider data={all}/>
                }
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
