import React from 'react';
import styled from "styled-components";
import {Container, Row, Col, Form} from "react-bootstrap";
import {Img} from "./Img";
import InvestInput from "./InvestInput";

const MyComponent = () => {
    return (
        <StyledComponent>
            <div id={'invest'} className="invest-area p-160 ">
                <div className="invest-area__bg">
                    <Img src={'/images/dynamic/invest-bg.jpg'}/>
                </div>
                <div className="invest-area__form">
                    <Container>
                        <Row>
                            <Col sm={12} className={'text-center'}>
                                <div className="invest-area__form__title">
                                    <h2>Investment <span>Solution</span></h2>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={{span: 8, offset: 2}}>
                                <InvestInput/>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  border-bottom: 1px solid #F8F8F9;
  
  .invest-area {
    &__form {
      padding: 120px 0px;
      h2 {
        color: #F8F8F9;
        span {
          font-size: 60px;
          font-weight: 500;
          letter-spacing: -3.6px;
          line-height: 72px;
          color: #CA0034;
          @media (max-width: 767px) {
            font-size: 36px;
            font-weight: 500;
            letter-spacing: -3.6px;
            line-height: 40px;

          }
        }
      }
    }
  }
`;

export default React.memo(MyComponent);
