import React, {memo, useEffect} from 'react';
import styled from "styled-components";
import gsap from 'gsap';

import { Col, Container, Row } from "react-bootstrap";
import reactHtmlParser from "react-html-parser";
import ButtonIcon from "./ButtonIcon";
import {ParallaxBg} from "./ParallaxBg";

const InnerBanner = ({ img, breadcrumbs, title, subtitle, buttonIcon }) => {
    useEffect(() => {
        // Use GSAP to animate the text elements
        gsap.from('.breadcrumbs, h1, .sub-title, .button-icon', {
            opacity: 0,
            y: 40,
            duration: 1,
            delay: 0.5,
            stagger: 0.2,
        });
    }, []); // Run once on component mount

    return (
        <StyledInnerBanner buttonIcon={buttonIcon} className='InnerBanner parallax'>
            <ParallaxBg  banner={true} src={img ? img : 'images/static/blur.jpg'} />
            <Container>
                <Row>
                    {
                        window.innerWidth > 1366 ?
                            <Col lg={{ span: 6, offset: 6 }}>
                                {breadcrumbs && <p className={'breadcrumbs'} style={{ textAlign: buttonIcon ? 'left' : 'right' }}>{reactHtmlParser(breadcrumbs)}</p>}
                                {title && <h1 style={{ textAlign: buttonIcon ? 'left' : 'right' }}>{reactHtmlParser(title)}</h1>}
                                {subtitle && <h6 className={'sub-title'} style={{ textAlign: buttonIcon ? 'left' : 'right' }}>{reactHtmlParser(subtitle)}</h6>}
                                {buttonIcon && <ButtonIcon src={'/usp-detail#invest'} text={'Ready to Invest!'} className="button-icon" />}
                            </Col>
                            :
                            <Col lg={{ span: 7, offset: 5 }}>
                                {breadcrumbs && <p className={'breadcrumbs'} style={{ textAlign: buttonIcon ? 'left' : 'right' }}>{reactHtmlParser(breadcrumbs)}</p>}
                                {title && <h1 style={{ textAlign: buttonIcon ? 'left' : 'right' }}>{reactHtmlParser(title)}</h1>}
                                {subtitle && <h6 className={'sub-title'} style={{ textAlign: buttonIcon ? 'left' : 'right' }}>{reactHtmlParser(subtitle)}</h6>}
                                {buttonIcon && <ButtonIcon src={'#invest'} text={'Ready to Invest!'} className="button-icon" />}
                            </Col>
                    }

                </Row>
            </Container>
        </StyledInnerBanner>
    );
};

// ... (rest of the component remains unchanged)



const StyledInnerBanner = styled.section`
    position: relative;
    background-color: #2B3944;
    height: calc(100vh);
    overflow: hidden;

    @media(min-width: 767px){
        //margin-top: 100px;
    }
    .container {
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        bottom: 20px;
        z-index: 2;
    }

    p {
        overflow: hidden;
    }

    h6 {
        overflow: hidden;
    }

    h1 {
        color: #F8F8F9;
        margin: 0 0 40px 0;
        //overflow: hidden;
        line-height: 90px;
        span {
            color: #F8F8F9;
        }
    }

    .breadcrumbs {
        color: #F8F8F9;
        margin: 0 0 20px 0;
    }

    .sub-title {
        color: #F8F8F9;
        padding-bottom: ${props => props.buttonIcon ? props.buttonIcon : ''}px;
    }

    //@media (min-width: 992px) {
    //    height: calc(100vh - 100px);
    //}

    @media (max-width: 991px) {
        //padding-top: calc(812 / 375 * 100%);
        height: 100vh;

        .container {
            bottom: 60px;
        }

        h2 {
            font-size: 60px;
            line-height: 60px;
            letter-spacing: -1.2px;
            margin: 0 0 40px 0;
        }
    }
`;

export default memo(InnerBanner);
