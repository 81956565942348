import React from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import TeamBox from "./TeamBox";
import Subtitle from "./Subtitle";

const Peoples = ({data, id, offset}) => {
    const peoples = data?.posts?.list;
    return (
        <StyledComponent offset={offset} className={'our-team pt-180 '} id={`${id ? id : ''}`} >
            <Container>
                <Row>
                    <Col>
                        <Subtitle
                            text={'Board of Directors\n'}/>
                    </Col>
                </Row>
                <Row>
                    <Col md={{span: 12}}>
                        <Row>
                            {
                                peoples?.map((item) => {
                                    return (
                                        <Col md={4}>
                                            <TeamBox description={item?.data?.description} md title={item?.data?.subtitle}
                                                     des={item?.data?.short_desc}
                                                     img={item?.images?.[0]?.full_path ? item?.images?.[0]?.full_path : 'images/static/blur.jpg'}/>
                                        </Col>
                                    )
                                })
                            }

                        </Row>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  padding-bottom: 100px;

h3{
    margin-bottom: 60px;
    letter-spacing: -0.8px;
    @media(max-width: 767px){
        margin-bottom: 40px;
    }
}
  .flot-col {
    p {
      font-size: 100px;
      letter-spacing: 1.5px;
      line-height: 80px;
      font-weight: 500;
      margin: 0;
      position: relative;
      top: 50%;

      &:first-child {
        transform: rotate(-90deg);
        position: absolute;
        top: 43%;
        left: -100px;
        //left: 40px;
      }

      &:last-child {
        transform: rotate(-90deg);
          top: 50%;
        //position: absolute;
        //top: 300px;
        //right: 30px;
      }
    }
  }

  .team-box {
    margin-bottom: 80px;
  }

  /* Normal desktop :1200px. */
  //@media (min-width: 1200px) and (max-width: 1536px) {
  //  .flot-col p:last-child {
  //    right: -50px;
  //  }
  //  .flot-col p:first-child {
  //    left: 0px;
  //  }
  //}

  @media (max-width: 1366px) {
    .flot-col p {
      font-size: 80px;
    }
    .flot-col p:last-child {
      right: -50px;
    }

    .flot-col p:first-child {
      left: -100px;
    }
  }


  /* Normal desktop :992px. */
  @media (min-width: 992px) and (max-width: 1200px) {


  }


  /* Tablet desktop :768px. */
  @media (min-width: 1366px) and (max-width: 1660px) {

    .flot-col p:last-child {
      right: -40px;
    }

    .flot-col p:first-child {
      left: -130px;
    }
  }

  @media (max-width: 768px) {
    .flot-col {
      display: none;

      p {
        font-size: 80px;
        line-height: 80px;
      }
    }
  }
`;

export default React.memo(Peoples);
