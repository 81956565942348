import React from 'react';
import styled from "styled-components";
import {hover} from "../styles/globalStyleVars";

const ArrowLeft = ({borderColor, iconColor, url}) => {
    return (
        <StyledComponent iconColor={iconColor} borderColor={borderColor}>
            <ul className="navigation">
                <li className={"next_swipper1 hover"}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="11.207" height="11.414"
                         viewBox="0 0 11.207 11.414">
                        <line id="Line_123721" data-name="Line 123721" x1="5" y2="5"
                              transform="translate(0.707 0.707)" fill="none" stroke="#2b3944" stroke-linecap="round"
                              stroke-width="1"/>
                        <line id="Line_123731" data-name="Line 123731" x1="5" y1="5"
                              transform="translate(0.707 5.707)" fill="none" stroke="#2b3944" stroke-linecap="round"
                              stroke-width="1"/>
                        <line id="Line_11" data-name="Line 11" x2="10"
                              transform="translate(10.707 5.707) rotate(180)" fill="none" stroke="#2b3944"
                              stroke-linecap="round" stroke-width="1"/>
                    </svg>
                </li>
            </ul>
        </StyledComponent>
    );
};

const StyledComponent = styled.div`
  .navigation {
    display: flex;
    align-items: center;

    li {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid ${props => props.borderColor ? props.borderColor : '#2B3944'};
      transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);

      svg {
        line {
          transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
        }
      }

      // &.hover:after {
        //   background-color: ${hover};
        //   border: 1px solid ${hover};
      // }


      position: relative;
      border-radius: 50%;
      height: 37px;
      width: 37px;

      svg {
        position: relative;

        line {
          stroke: #fff;
        }
      }
    }
  }


  .navigation li:hover {
    border: 1px solid #CA0034;

    svg {
      line {
        stroke: white;
      }
    }
  }

  .swiper-button-disabled {
    opacity: 1 !important;
  }
`;

export default React.memo(ArrowLeft);
