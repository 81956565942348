import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";

const OverviewList = ({offset, data}) => {
    return (
        <StyledComponent>
            <Container fluid className={'about-overview'} style={{paddingLeft: offset + 15 + 'px'}}>

                <Row className="about-overview__data">
                    {
                        data?.data?.subtitle && <Col md={{span: 5, offset: 1}} className="about-overview__data__left">
                            <h4>{reactHtmlParser(data?.data?.subtitle ? data?.data?.subtitle : 'Experience the future of automotive fuels with our Autogas service ')}</h4>
                        </Col>
                    }
                    {
                        data?.data?.description &&
                        <Col md={6} className="about-overview__data__right" style={{paddingRight: offset + 15 + 'px'}}>
                            {reactHtmlParser(data?.data?.description)}
                        </Col>
                    }
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;

  h4 {
    padding-right: 100px;
  }

  &:before {
    content: '';
    position: absolute;
    top: -1px;
    left: 0;
    right: 0;
    height: 50%;
    z-index: 0;
    background-color: #2B3944;
  }

  .container-fluid {
    padding-right: 0;

    @media (max-width: 767px) {
      padding-left: 0px !important;
    }
  }

  .row {
    background: #83909D;
    position: relative;
    z-index: 1;

    img {
      width: 100%;
      height: 100%;
      object-fit: unset;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    @media (max-width: 767px) {
      padding-left: 15px !important;
    }
  }

  h4 {
    font-size: 36px;
    font-weight: 400;
    line-height: 40px;
    color: #F8F8F9;
    margin-bottom: 0px;
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 767px) {
      margin-bottom: 40px;
    }
  }

  .about-overview {
    &__data {
      padding-top: 120px;
      padding-bottom: 120px;

      &__right {
        ul {
          li {
            display: flex;
            gap: 60px;

            p {
              color: #F8F8F9;
              font-size: 20px;
              line-height: 28px;
              letter-spacing: -1.2px;
              font-weight: 500;
              display: flex;
              flex-wrap: wrap;
            }

            padding-bottom: 30px;
            border-bottom: 1px solid rgba(248, 248, 249, 0.5);
            margin-bottom: 30px;

            &:nth-last-of-type(1) {
              border-bottom: unset;
              padding-bottom: unset;
              margin-bottom: unset;
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    h4 {
      font-size: 28px;
      font-weight: 400;
      line-height: 36px;
      padding-right: unset;
    }

    .about-overview__data__left {
      margin-right: 15px;
    }

    .overview__data__right {
      padding-right: 0px !important;
    }

    ul {
      li {
        padding: 0px;
      }
    }

    ul li::before {
      display: none;
    }

    .about-overview {
      &__data {
        padding: 120px 0px;

        &__right {
          padding-right: 30px !important;
        }
      }
    }
  }
`;

export default React.memo(OverviewList);
