import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from 'react-bootstrap';
import {gsap} from "gsap";


const Category = ({countries, handleCategoryClick, activeCategory}) => {
    //sticky
    useEffect(() => {
        if (window?.innerWidth > 767) {
            gsap.to(".category", {
                scrollTrigger: {
                    trigger: ".category",
                    start: "top top",
                    endTrigger: ".category",
                    end: "bottom-=250px top",
                    pin: true,
                    pinSpacing: false
                }
            });
        }
    }, []);

    const categoriesWithAll = Array.isArray(countries) ? [{id: 0, title: 'All'}, ...countries] : [];
    return (
        <StyledComponent className={'category'}>
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="categories">
                            {['All', 'News', 'Blog']?.map((category, index) => {
                                return (
                                    <h6
                                        key={index}
                                        onClick={() => handleCategoryClick(category)}
                                        className={activeCategory === category  ? 'active' : ''}
                                    >
                                        {category}
                                    </h6>
                                )
                            })}
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background: #ca0034;

  .categories {
    display: flex;
    padding: 20px 0px;
    gap: 60px;

    h6 {
      color: rgba(248, 248, 249, 0.6);
      cursor: pointer;
      transition: color 0.3s ease;

      &.active {
        color: rgba(248, 248, 249);
      }

      &:hover {
        color: white;
      }
    }
  }
`;

export default React.memo(Category);
