import React, {memo, useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import styled from "styled-components";
import Banner from "../../components/home/Banner";
import Brands from "../../components/home/Brands";
import VideoFeatures from "../../components/home/VideoFeatures";
import InsightSlider from "../../components/home/InsightSlider";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {ApiParam} from "../../api/network/apiParams";
import {fetchHomeData} from "../../api/redux/home";
import HomeServices from "../../components/home/HomeServices";
import {motion} from "framer-motion";
import {PageAnimation} from "../../components/PageAnimation";
import Cta from "../../components/Cta";
import BrandsV2 from "../../components/home/BrandsV2";
const Home = ({offset}) => {
    const dispath = useDispatch();

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS;

        let api_params = {
            [ApiParam.TYPE]: "slug",
            [ApiParam.VALUE]: "home",
            [ApiParam.GET_SECTION]: "yes",
            [ApiParam.IMAGE]: "yes",
            [ApiParam.POST]: "yes",
        };

        dispath(fetchHomeData([api_url, api_params]));
    }, [PageAnimation]);

    const getData = useSelector((state) => state.home);
    const metaData = getData?.posts?.data?.page_data;
    const bannerData = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "banner");
    const serviceSubtitle = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "home-service");
    const services = getData?.posts?.services?.list;
    const brandData = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "brands");
    const videoData = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "video");
    const getInTouchData = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "get-in-touch");
    const mediaCenterSubtitle = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "media-center-subtitle");
    const mediaCenter = getData?.posts?.media_center?.list?.filter((f) => f?.page_data?.type !== "Gallery")?.slice(0, 3);
    console.log(mediaCenter)

    // create condition of cta/get in touch mobile image condition
    const desktop = getInTouchData?.images?.list?.filter(f => f?.desktop === 'on') ;
    const mobile = getInTouchData?.images?.list?.filter(f => f?.mobile === 'on') ;

    // getInTouchData?.images?.list.forEach(item => {
    //     if (item.desktop === 'on') {
    //         desktop = item;
    //     }
    //     if (item.mobile === 'on') {
    //         mobile = item;
    //     }
    // });

    return (
        <HelmetProvider>
            <Helmet>
                <title>{metaData?.title_tag}</title>
                <meta property="og:title" content={metaData?.og_title}/>
                <meta property="og:subtitle" content={metaData?.og_title} />
                <meta property="og:description" content={metaData?.og_description}/>
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website"/>
                <meta name="description" content={metaData?.og_description} />
            </Helmet>
            <motion.div key={`83`} className="page-loader" style={{height: `${document.body.clientHeight}px`}}
                        exit="exit" animate="anim"
                        variants={PageAnimation}
                        initial="init">
            </motion.div>
            <StyledSection>
                {/*<div className="faker"></div>*/}
                <Banner bannerData={bannerData} offset={offset}/>
                <HomeServices dataContent={services} data={serviceSubtitle}/>
                <BrandsV2 offset={offset} data={brandData} />
                <VideoFeatures data={videoData} offset={offset}/>
                <Cta
                    title={getInTouchData?.section_data?.subtitle}
                    desktopImg={desktop?.[0]?.full_path}
                    mobImg={mobile?.[0]?.full_path}
                    buttonText={getInTouchData?.section_data?.title}
                    link={'/contact'}
                />
                <InsightSlider pt data={mediaCenter} subtitle={mediaCenterSubtitle}/>
            </StyledSection>
        </HelmetProvider>
    );
};

const StyledSection = styled.div`
    
`;

export default memo(Home);
