import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";

import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from "swiper";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import ReactHtmlParser from "react-html-parser";
import {Img} from "../Img";
import Subtitle from "../Subtitle";
import NextBtn from "../NextBtn";

const Brands = ({data, offset}) => {
    const subtitle = data?.section_data?.subtitle;
    const brands = data?.posts?.list?.[0]?.images;

    return (
        <StyledStrength
            offset={offset}
            className={`pionneering parallax-inner-box  customers`}>
            <Container>
                <Row>
                    <Col md={12}>
                        <div className="d-flex navigation_wrapper">
                            <div>
                                <Subtitle text={subtitle ? subtitle : 'Fostering innovation <span>together</span>'}/>
                            </div>
                            {
                                brands?.length > 4 && <ul className="navigation">
                                    <NextBtn prevId={'prev_brand'} nextId={'next_brand'}/>
                                </ul>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
            <div style={{paddingLeft: offset + 15 + 'px'}} className='about-section__bottom'>
                <Row>
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={3}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}
                        breakpoints={{
                            320: {
                                slidesPerView: 2,
                                spaceBetween: 15,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 15,
                            },
                            1024: {
                                slidesPerView: 4,
                                spaceBetween: 30,
                            },
                        }}
                        navigation={{
                            prevEl: '#prev_brand',
                            nextEl: '#next_brand',
                        }}
                        modules={[Navigation]}
                    >
                        {
                            brands?.map((item) => {
                                return (
                                    <SwiperSlide key={item?.id}>
                                        <Col className='about-section__bottom__single p-0'>
                                            <div className={'single'}>
                                                <a>
                                                    <div className="single_wrp">
                                                        <div className="single_wrp__content-text">
                                                            <Img alt={item?.short_title}
                                                                 src={item?.full_path ? item?.full_path : '/images/dynamic/brands/brand-01.jpg'}/>
                                                            <p>{ReactHtmlParser(item?.short_title ? item?.short_title : 'Lorem ipsum')} </p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </Col>
                                    </SwiperSlide>
                                )

                            })
                        }
                    </Swiper>
                </Row>
            </div>
        </StyledStrength
        >
    );
};

const StyledStrength = styled.section`
  position: relative;
  overflow: hidden;

  .swiper-initialized {
    @media (max-width: 767px) {
      padding-right: 0 !important;
      width: 100%;
    }
  }

  .navigation_wrapper {
    justify-content: space-between;
    margin-bottom: 80px;


    @media (max-width: 767px) {
      margin-bottom: 40px;
    }
  }

  .swiper-wrapper {
    height: auto !important;
  }

  .about-section__bottom {

    position: relative;

    &::after {
      position: absolute;
      content: '';
      background: #2B3944;
      height: 50%;
      width: 100%;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .single_wrp {
      position: relative;
      overflow: hidden;
      height: 230px;
      box-shadow: 0 10px 10px 0px rgba(0, 0, 0, 0.05);

      &__content-text {
        //position: absolute;
        z-index: 2;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        justify-content: center;
        background: #fff;

        .global-image {
          width: calc(100% - 80px);
          margin: auto;
          height: 120px;

          img {
            object-fit: contain !important;
          }
        }

        p {

          margin: 0 auto;
          font-weight: 500;
          width: 50%;
          text-align: center;
          position: relative;
          top: 20px;
          opacity: 0;
          transition: 0.5s ease-in-out;
        }
      }

      .global-image {
        img {
          transition: 0.5s ease-in-out;
        }
      }

      &:hover {
        .global-image {
          img {
            top: -20px;
            opacity: 0;
          }
        }

        p {
          top: 0;
          opacity: 1;
        }
      }
    }


    /* Normal desktop :992px. */
    @media (min-width: 992px) and (max-width: 1200px) {
      .single_wrp {
        height: 200px
      }
    }
  }


  /* Tablet desktop :768px. */
  @media (min-width: 768px) and (max-width: 991px) {
    .about-section__bottom {
      padding-left: ${props => props.offset + 30 + 'px'}!important;
    }
  }


  /* small mobile :320px. */
  @media (max-width: 767px) {
    .about-section__bottom {
      padding-left: 30px!important;
    }
    .single_wrp {
      height: 160px!important;
    }
  }





`;

export default Brands;
