import {configureStore} from "@reduxjs/toolkit";
import postReducer from '../redux/post'
import globalReducer from '../redux/global'
import homeReducer from '../redux/home'
import servicesReducer from '../redux/services'
import aboutReducer from '../redux/about'
import mediaReducer from '../redux/media'
import contactReducer from '../redux/contact'
import contactFormReducer from '../redux/contact'
import upsReducer from '../redux/ups'
import stationReducer from '../redux/station'
import footerReducer from '../redux/footer'

const store = configureStore({
    reducer: {
        postReducer,
        globalReducer,
        home: homeReducer,
        services: servicesReducer,
        about: aboutReducer,
        contact: contactReducer,
        media: mediaReducer,
        ups: upsReducer,
        station: stationReducer,
        footer: footerReducer,
        contact_form: contactFormReducer,
    },
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)
})

export default store

