import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton} from "react-share";
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";
import VideoModal from "../VideoModal";
import {gsap} from "gsap";
import {hover, text} from "../../styles/globalStyleVars";
import Video from "../media/Video";


const MyComponent = ({data,description, video, images, introduction}) => {

    const [width, setWidth] = useState(0)
    useEffect(() => {
        setWidth(window.innerWidth)
    }, [])

    //sticky
    useEffect(() => {
        if (window?.innerWidth > 767) {
            gsap.to(".detail-page__text-content__detail", {
                scrollTrigger: {
                    trigger: ".detail-page__text-content__share",
                    start: "top top",
                    endTrigger: ".detail-page__text-content__detail",
                    end: "bottom-=250px top",
                    pin: true,
                    pinSpacing: false
                }
            });
        }

        window.addEventListener('resize', () => {
            if (window.innerWidth < 767) {
                document.querySelector('.desktop-social').style.display = 'flex';
            } else {
                document.querySelector('.desktop-social').style.display = 'none';
            }
        })

    }, []);

    // const router = useRouter();

    const [shareUrl, setShareUrl] = useState('')
    useEffect(() => {
        setShareUrl(window.location.href)
    }, [])



    let [open, setOpen] = useState(false);
    let handelOpen = (open, id) => {
        setOpen(open);
        setShow(true)
    };


    let [videoId, setVideo] = useState('');


    const [show, setShow] = useState(false);
    const [popupId, setPopupId] = useState()

    const handleClose = () => setShow(false);
    const handleShow = (e) => {
        setShow(true)
        setPopupId(e)
        setVideo(true)
    }
    const handleCloseModal = () => {
        setOpen(false);
        handleClose();
    };


    return (
        <StyledComponent className={'detail-page'}>
            <div className="desktop-social">
                <p>Share</p>
                <ul>
                    <li className={'hover'}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="4.877" height="10.466"
                             viewBox="0 0 4.877 10.466">
                            <path id="Path_26" data-name="Path 26"
                                  d="M1012.361,3918.425v-.9a1.965,1.965,0,0,1,.019-.315.612.612,0,0,1,.086-.219.351.351,0,0,1,.216-.149,1.551,1.551,0,0,1,.4-.041h.9V3915h-1.441a2.344,2.344,0,0,0-1.8.594,2.5,2.5,0,0,0-.546,1.75v1.086h-1.08v1.8h1.079v5.233h2.166v-5.233h1.442l.19-1.8Z"
                                  transform="translate(-1009.116 -3914.995)" fill="#fff"/>
                        </svg>
                        <FacebookShareButton url={shareUrl}/>
                    </li>
                    <li className={'hover'}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="11.997" height="9.75"
                             viewBox="0 0 11.997 9.75">
                            <path id="Path_5915" data-name="Path 5915"
                                  d="M453.489,20.125a6.955,6.955,0,0,0,7-7q0-.16-.007-.318a5.011,5.011,0,0,0,1.228-1.274,4.912,4.912,0,0,1-1.413.387,2.47,2.47,0,0,0,1.082-1.362,4.933,4.933,0,0,1-1.563.6,2.464,2.464,0,0,0-4.195,2.245,6.988,6.988,0,0,1-5.073-2.571,2.463,2.463,0,0,0,.762,3.286A2.444,2.444,0,0,1,450.2,13.8c0,.01,0,.021,0,.031a2.462,2.462,0,0,0,1.975,2.413,2.459,2.459,0,0,1-1.111.042,2.464,2.464,0,0,0,2.3,1.71,4.938,4.938,0,0,1-3.057,1.054,5,5,0,0,1-.587-.034,6.968,6.968,0,0,0,3.773,1.106"
                                  transform="translate(-449.716 -10.375)" fill="#fff"/>
                        </svg>
                        <TwitterShareButton url={shareUrl}/>
                    </li>
                    <li className={'hover'}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="9.063" height="8.561"
                             viewBox="0 0 9.063 8.561">
                            <path id="LinkedIn"
                                  d="M1096.5,3921.554v3.313h-1.942v-3.091c0-.776-.281-1.306-.984-1.306a1.061,1.061,0,0,0-1,.7,1.3,1.3,0,0,0-.065.469v3.226h-1.943s.026-5.234,0-5.777h1.943v.82l-.013.018h.013v-.018a1.932,1.932,0,0,1,1.751-.955C1095.539,3918.955,1096.5,3919.781,1096.5,3921.554Zm-7.964-5.249a1,1,0,1,0-.026,2h.013a1,1,0,1,0,.013-2Zm-.984,8.561h1.943v-5.777h-1.943Z"
                                  transform="translate(-1087.435 -3916.306)" fill="#fff"/>
                        </svg>
                        <LinkedinShareButton url={shareUrl}/>
                        {/*<InstapaperIcon url={shareUrl}/>*/}
                    </li>
                </ul>

            </div>
            {
                data?.images?.list?.[0]?.full_path &&   <div className={'detail-page__img'}>
                    <Img src={data?.images?.list?.[0]?.full_path}/>
                </div>
            }

            <Container className={'detail-page__text-content'}>
                <Row>
                    <Col sm={2} className={'detail-page__text-content__share'}>
                        <div>
                            <p>Share:</p>
                            <ul>
                                <li className={'hover'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="4.877" height="10.466"
                                         viewBox="0 0 4.877 10.466">
                                        <path id="Path_26" data-name="Path 26"
                                              d="M1012.361,3918.425v-.9a1.965,1.965,0,0,1,.019-.315.612.612,0,0,1,.086-.219.351.351,0,0,1,.216-.149,1.551,1.551,0,0,1,.4-.041h.9V3915h-1.441a2.344,2.344,0,0,0-1.8.594,2.5,2.5,0,0,0-.546,1.75v1.086h-1.08v1.8h1.079v5.233h2.166v-5.233h1.442l.19-1.8Z"
                                              transform="translate(-1009.116 -3914.995)" fill="#fff"/>
                                    </svg>
                                    <FacebookShareButton url={shareUrl}/>
                                </li>
                                <li className={'hover'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="11.997" height="9.75"
                                         viewBox="0 0 11.997 9.75">
                                        <path id="Path_5915" data-name="Path 5915"
                                              d="M453.489,20.125a6.955,6.955,0,0,0,7-7q0-.16-.007-.318a5.011,5.011,0,0,0,1.228-1.274,4.912,4.912,0,0,1-1.413.387,2.47,2.47,0,0,0,1.082-1.362,4.933,4.933,0,0,1-1.563.6,2.464,2.464,0,0,0-4.195,2.245,6.988,6.988,0,0,1-5.073-2.571,2.463,2.463,0,0,0,.762,3.286A2.444,2.444,0,0,1,450.2,13.8c0,.01,0,.021,0,.031a2.462,2.462,0,0,0,1.975,2.413,2.459,2.459,0,0,1-1.111.042,2.464,2.464,0,0,0,2.3,1.71,4.938,4.938,0,0,1-3.057,1.054,5,5,0,0,1-.587-.034,6.968,6.968,0,0,0,3.773,1.106"
                                              transform="translate(-449.716 -10.375)" fill="#fff"/>
                                    </svg>
                                    <TwitterShareButton url={shareUrl}/>
                                </li>
                                <li className={'hover'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="9.063" height="8.561"
                                         viewBox="0 0 9.063 8.561">
                                        <path id="LinkedIn"
                                              d="M1096.5,3921.554v3.313h-1.942v-3.091c0-.776-.281-1.306-.984-1.306a1.061,1.061,0,0,0-1,.7,1.3,1.3,0,0,0-.065.469v3.226h-1.943s.026-5.234,0-5.777h1.943v.82l-.013.018h.013v-.018a1.932,1.932,0,0,1,1.751-.955C1095.539,3918.955,1096.5,3919.781,1096.5,3921.554Zm-7.964-5.249a1,1,0,1,0-.026,2h.013a1,1,0,1,0,.013-2Zm-.984,8.561h1.943v-5.777h-1.943Z"
                                              transform="translate(-1087.435 -3916.306)" fill="#fff"/>
                                    </svg>
                                    <LinkedinShareButton url={shareUrl}/>
                                    {/*<InstapaperIcon url={shareUrl}/>*/}
                                </li>
                            </ul>

                        </div>
                        <div className={'mobile-share'}>
                            <p>Share:</p>
                            <ul>
                                <li className={'hover'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="4.877" height="10.466"
                                         viewBox="0 0 4.877 10.466">
                                        <path id="Path_26" data-name="Path 26"
                                              d="M1012.361,3918.425v-.9a1.965,1.965,0,0,1,.019-.315.612.612,0,0,1,.086-.219.351.351,0,0,1,.216-.149,1.551,1.551,0,0,1,.4-.041h.9V3915h-1.441a2.344,2.344,0,0,0-1.8.594,2.5,2.5,0,0,0-.546,1.75v1.086h-1.08v1.8h1.079v5.233h2.166v-5.233h1.442l.19-1.8Z"
                                              transform="translate(-1009.116 -3914.995)" fill="#fff"/>
                                    </svg>
                                    <FacebookShareButton url={shareUrl}/>
                                </li>
                                <li className={'hover'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="11.997" height="9.75"
                                         viewBox="0 0 11.997 9.75">
                                        <path id="Path_5915" data-name="Path 5915"
                                              d="M453.489,20.125a6.955,6.955,0,0,0,7-7q0-.16-.007-.318a5.011,5.011,0,0,0,1.228-1.274,4.912,4.912,0,0,1-1.413.387,2.47,2.47,0,0,0,1.082-1.362,4.933,4.933,0,0,1-1.563.6,2.464,2.464,0,0,0-4.195,2.245,6.988,6.988,0,0,1-5.073-2.571,2.463,2.463,0,0,0,.762,3.286A2.444,2.444,0,0,1,450.2,13.8c0,.01,0,.021,0,.031a2.462,2.462,0,0,0,1.975,2.413,2.459,2.459,0,0,1-1.111.042,2.464,2.464,0,0,0,2.3,1.71,4.938,4.938,0,0,1-3.057,1.054,5,5,0,0,1-.587-.034,6.968,6.968,0,0,0,3.773,1.106"
                                              transform="translate(-449.716 -10.375)" fill="#fff"/>
                                    </svg>
                                    <TwitterShareButton url={shareUrl}/>
                                </li>
                                <li className={'hover'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="9.063" height="8.561"
                                         viewBox="0 0 9.063 8.561">
                                        <path id="LinkedIn"
                                              d="M1096.5,3921.554v3.313h-1.942v-3.091c0-.776-.281-1.306-.984-1.306a1.061,1.061,0,0,0-1,.7,1.3,1.3,0,0,0-.065.469v3.226h-1.943s.026-5.234,0-5.777h1.943v.82l-.013.018h.013v-.018a1.932,1.932,0,0,1,1.751-.955C1095.539,3918.955,1096.5,3919.781,1096.5,3921.554Zm-7.964-5.249a1,1,0,1,0-.026,2h.013a1,1,0,1,0,.013-2Zm-.984,8.561h1.943v-5.777h-1.943Z"
                                              transform="translate(-1087.435 -3916.306)" fill="#fff"/>
                                    </svg>
                                    <LinkedinShareButton url={shareUrl}/>
                                </li>
                            </ul>
                        </div>

                    </Col>
                    {/*it will replace with react html parser*/}
                    <Col sm={10} className={'detail-page__text-content__detail'}>
                        <div className={'full-detail'}>
                            <div className={'detail-page__text-content__detail__date '}>
                                {data?.page_data?.type && <p>{data?.page_data?.type}</p>}
                                {data?.page_data?.subtitle && <p>{data?.page_data?.subtitle}</p>}
                            </div>
                            {data?.page_data?.title &&  <h3>{data?.page_data?.title}</h3>}

                            {description?.data?.description && reactHtmlParser(description?.data?.description)}
                            {video && <Video data={video}/>}
                            {images && <img src={images?.images?.find((f) => f.short_title === "Full")?.full_path}/>}
                            {images && <Row className={'mt-4'}>
                                <Col xs={6}>
                                    <div className="left">
                                        <img src={images?.images?.find((f) => f.short_title === "Left")?.full_path}/>
                                    </div>
                                </Col>
                                <Col xs={6}>
                                    <div className="right">
                                        <img src={images?.images?.find((f) => f.short_title === "Right")?.full_path} alt={'image'} />
                                    </div>
                                </Col>

                            </Row>}

                        </div>
                    </Col>
                </Row>

            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  margin-bottom: 60px;

  .full-detail {
    border-bottom: 1px solid ${text};
    padding-bottom: 60px;

    p {
      padding-bottom: 60px;
    }
  }

  .insight-slider {
    padding-top: 160px;
    /* small mobile :320px. */
    @media (max-width: 767px) {
      padding-top: 120px;
    }
  }

  .left-right {
    display: flex;
    margin-left: 15px;
    margin-right: 15px;
    gap: 30px;
  }

  .desktop-social {
    justify-content: space-between;
    margin-left: 15px;
    margin-right: 15px;
    align-items: center;
    margin-top: 70px;
    display: none;

    p {
      font-weight: 500;
    }

    ul {
      display: flex;
      gap: 15px;
      li {
        min-height: 30px;
        height: 30px;
        width: 30px;
        min-width: 30px;
        border-radius: 50%;
        background-color: #9CABB9;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-bottom: 20px;
        position: relative;

        button {
          position: absolute;
          height: 100%;
          width: 100%;
          z-index: 2;
        }

        svg {
          position: relative;
          z-index: 2;

          path {
            fill: #ffffff;
            transition: .3s ease;
          }
        }
        
        .hover:after{
          background-color: #CA0034;
        }
        
        &:hover {
          svg path {
            fill: #ffffff !important;
          }
        }
      }

    }
  }
  

  .detail-page__img {
    position: relative;
    padding-top: calc(600 / 1366 * 100%);
    @media (max-width: 767px) {
      padding-top: calc(160 / 375 * 100%);
    }
  }

  h6 {
    margin-bottom: 20px;
  }


  .detail-page__heading {
    h1 {
      font-size: 32px;
      line-height: 36px;
      color: #CA0034;
      font-weight: 600;
    }

    &__tags {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      p {
        color: ${hover};
        font-size: 16px;
        line-height: 24px;
      }

      ul {
        display: flex;

        li {

          font-size: 16px;
          line-height: 24px;
          color: ${hover};

          &:not(:nth-last-child(1)) {
            padding-right: 20px;
            margin-right: 20px;
            border-right: 0;
            border-right: 1px solid #CED7ED;
          }
        }
      }
    }

  }

  .detail-page__banner {
    width: 100%;

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  .hover:after {
    border-radius: 50%;
  }

  .detail-page__text-content {
    &__share {
      padding-top: 40px;

      .mobile-share {
        display: none;
      }


      @media (max-width: 767px) {
        //border-bottom: 1px solid rgba(217, 217, 217, 0.5) !important;
        .mobile-share {
          display: flex;
          justify-content: space-between;
          width: 100%;
          border-bottom: 1px solid rgba(217, 217, 217, 0.5) !important;
        }

      }
      @media (max-width: 767px) {
        //border-bottom: 1px solid rgba(217, 217, 217, 0.5) !important;
        .mobile-share {
          display: flex;
          justify-content: space-between;
          width: 100%;
          border-bottom: 1px solid rgba(217, 217, 217, 0.5) !important;
          display: none;

        }

        .sticky-outer-wrapper {
          display: none;
        }
      }


      p {
        margin-bottom: 30px;
      }

      ul {
        li {
          min-height: 30px;
          height: 30px;
          width: 30px;
          min-width: 30px;
          border-radius: 50%;
          background-color: #9CABB9;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          margin-bottom: 15px;
          position: relative;

          button {
            position: absolute;
            height: 100%;
            width: 100%;
            z-index: 2;
          }

          .hover:after{
            background-color: #CA0034;
          }
          svg {
            position: relative;
            z-index: 2;

            path {
              fill: #ffffff;
              transition: .3s ease;
            }
          }

          &:hover {
            svg path {
              fill: #ffffff !important
            }
          }
        }

      }

      @media (max-width: 767px) {
        display: flex;
        justify-content: space-between !important;
      }
    }

    &__detail {
      padding-top: 40px;

      p {
        letter-spacing: 0;
      }

      .custom-list {
        padding: 0;

        li {
          position: relative;
          padding-left: 20px;
          //padding-top: 20px;
          &:not(:last-child) {
            padding-bottom: 0px;
          }

          &:before {
            content: '';
            position: absolute;
            top: 12px;
            left: 0;
            margin-top: -5px;
            width: 8px;
            height: 8px;
            box-sizing: content-box;
            background-color: ${hover};
          }
        }

      }

      img {
        width: 100%;
      }

      span {
        p {
          color: ${hover};
          font-weight: 500;
          margin-top: 20px;
        }
      }

      &__date {
        display: flex;
        justify-content: space-between;


        p {
          font-weight: 300 !important;
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 30px !important;
        }

        @media (max-width: 767px) {
          display: flex;
          p {
            &:not(last-child) {
              margin-bottom: 10px !important;
            }
          }
        }
      }

      h3 {
        padding-bottom: 60px;
        border-bottom: 1px solid #9CABB9;
        margin-bottom: 60px;
      }

      h2 {
        font-size: 28px;
        line-height: 36px;
        font-weight: 500;
        margin-bottom: 20px;
      }

      p {
        font-weight: 400;
      }

      .quotes {
        padding-left: 20px;
        position: relative;
        margin-bottom: 40px;
        font-size: 16px;

        &:after {
          content: '';
          position: absolute;
          width: 4px;
          height: 100%;
          left: 0;
          top: 0;
          background-color: ${hover};
        }

        p {
          padding-bottom: 40px;

          &:nth-last-of-type(1) {
            padding-bottom: unset;
          }
        }

      }

      table {
        min-width: 100%;
        margin-bottom: 30px;


        th {
          color: ${hover};
          border-bottom: 1px solid ${text};
          padding: 20px 0;
          font-weight: 500 !important;
        }

        td {
          padding: 20px 0;
          border-bottom: 1px solid rgba(86, 87, 90, 0.2);

          &:nth-of-type(1) {
            padding-right: 200px;
            @media (max-width: 767px) {
              padding-right: 30px;
            }
          }
        }
      }

      .custom-list {
        padding: 0;

        li {

          position: relative;
          padding-left: 20px;
          padding-top: 20px;

          &:not(:last-child) {
            padding-bottom: 20px;
            border-bottom: 1px solid rgba(86, 87, 90, 0.2);
          }

          &:before {
            content: '';
            position: absolute;
            top: 12px;
            left: 0;
            margin-top: 13px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            box-sizing: content-box;
            background-color: ${hover};
          }
        }

      }

      .video-box {
        position: relative;
        cursor: pointer;
        margin-top: 30px;
        width: 100%;
        margin-bottom: 60px;

        img {
          width: 100%;
        }

        .hover-btn {
          height: 80px;
          width: 80px;
          background-color: ${hover};
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          z-index: 2;
          top: 0;
          bottom: 0;
          margin: auto;
          left: 0;
          right: 0;

          &.hover:after {
            border-radius: 50%;
          }

          svg {
            position: relative;
            z-index: 2;
          }

          path {
            transition: all .3s ease;
          }

        }

        &:hover {

          .hover-btn {
            &:after {
              height: 100%;
              width: 100%;
            }

            path {
              fill: #FFF;
            }
          }
        }
      }


    }

  }


  @media (max-width: 767px) {

    //margin-bottom: 80px;
    .detail-page__heading__tags {
      flex-wrap: wrap;

      ul {
        min-width: 100%;
        margin-bottom: 20px;
      }

      p {
        min-width: 100%;
      }
    }

    .detail-page__text-content__share {
      margin-bottom: 60px;
      min-width: 100%;
      display: none;

      ul {
        display: flex;

        li {
          margin-right: 10px;
        }
      }
    }

    .detail-page__text-content__detail {
      min-width: 100%;
    }
  }
  //.sticky-inner-wrapper{
  //  top:120px !important;
  //}
  @media (max-width: 767px) {
    .title h2 {
      text-align: start !important;
    }

    .full-detail p {
      padding-bottom: 30px;

      &:nth-last-of-type(1) {
        padding-bottom: unset;
      }
    }

    .detail-page__text-content__detail__date p:not(last-child) {
      margin-bottom: unset !important;
    }

    .detail-page__text-content__detail h3 {
      padding-bottom: 30px;
      margin-bottom: 30px;
    }
  }

  .hover::after{
    background-color: #CA0034 !important;
  }
`;

export default MyComponent;
