import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";

const MissionVision = ({ data}) => {
    const [offset, setOffset] = useState(90)

    useEffect(() => {
        setOffset(document.querySelector(' .container').offsetLeft)
    }, [data])
    const subtitle = data?.[1]?.section_data?.subtitle;
    const missionVision = data?.[1]?.posts?.list;

    return (
        <StyledComponent>
            <Container fluid className={'about-overview'} style={{paddingLeft: offset + 15 + 'px'}}>

                <Row className="about-overview__data pt-160 pb-160">
                    <Col md={{span: 5, offset: 1}} className="about-overview__data__left">
                        <h4>{
                            reactHtmlParser(subtitle ? subtitle : 'Unwavering dedication to your energy needs.')
                        }</h4>
                    </Col>

                    <Col md={6} className="about-overview__data__right" style={{paddingRight: offset + 15 + 'px'}}>
                        <ul>
                            <li>
                                <h4>{missionVision?.[0]?.data?.title}</h4>
                                <p>{missionVision?.[0]?.data?.description}</p>
                            </li>
                            <li>
                                <h4>{missionVision?.[1]?.data?.title}</h4>
                                <p>{missionVision?.[1]?.data?.description}</p>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;

  h4 {
    overflow: hidden;
  }
  
  .about-overview__data__right {
    ul {
      li {
        p {
          font-size: 16px;
        }
      }
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: -1px;
    left: 0;
    right: 0;
    height: 50%;
    z-index: 0;
    background-color: #2B3944;
  }

  .container-fluid {
    padding-right: 0;

    @media (max-width: 767px) {
      padding-left: 0px !important;
    }
  }

  .row {
    background: #83909D;
    position: relative;
    z-index: 1;

    img {
      width: 100%;
      height: 100%;
      object-fit: unset;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    @media (max-width: 767px) {
      padding-left: 15px !important;
    }
  }

  h4 {
    font-size: 36px;
    font-weight: 400;
    line-height: 40px;
    color: #ffffff;
    margin-bottom: 0px;
    padding-right: 100px;
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 767px) {
      margin-bottom: 40px;
      padding-right: unset;
    }
  }

  ul {
    li {
      counter-increment: count;
      position: relative;
      padding: 0 0 0 70px;

      &:before {
        content: counter(count, decimal);
        font-size: 65px;
        line-height: 80px;
        font-weight: 500;
        color: #ffffff;
        position: absolute;
        left: 0;
        top: 0;
      }

      h4 {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        margin: 0 0 20px 0;
        color: rgba(255, 255, 255, 0.5);
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
      }

      p {
        font-size: 20px;
        line-height: 28px;
        font-weight: 500;
        margin: 0;
        color: #ffffff;
        display: flex;
        flex-wrap: wrap;
      }

      &:nth-child(1) {
        border-bottom: 1px solid #C4C4C4;

        p {
          margin: 0 0 80px 0;
        }
      }

      &:nth-child(2) {
        &:before {
          top: 80px;
        }

        h4 {
          padding: 80px 0 0 0;
        }
      }
    }

    @media (max-width: 767px) {
      li {
        &:nth-child(1) {
          p {
            margin: 0 0 40px 0;
          }
        }

        &:nth-child(2) {
          &:before {
            top: 40px;
          }

          h4 {
            padding: 40px 0 0 0;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    h4 {
      font-size: 28px;
      font-weight: 400;
      line-height: 36px;
    }

    .about-overview__data__left {
      margin-right: 15px !important;
    }

    .overview__data__right {
      padding-right: 0px !important;
    }

    ul li {
      padding: 0px;
    }

    ul li::before {
      display: none;
    }

    .about-overview__data__right {
      padding-right: 30px !important;
    }
  }
`;

export default React.memo(MissionVision);
