import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Title from "../Title";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import 'swiper/css/scrollbar';
import SwiperCore, {Autoplay, Navigation, Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react"
import {Img} from "../Img";
import Subtitle from "../Subtitle";
import NextBtn from "../NextBtn";


const MyComponent = ({title,data}) => {
    const [offset, setOffset] = useState()
    // useEffect(() => {
    //     setOffset(document.querySelector('.container').offsetLeft)
    // }, [])
    useEffect(() => {

        // ScrollTrigger.refresh();
        // gsap.registerPlugin(ScrollTrigger);


        window.addEventListener('load', function () {
            setOffset(document.querySelector(' .container')?.offsetLeft)

        })
        window.addEventListener('resize', function () {
            setOffset(document.querySelector(' .container')?.offsetLeft)

        })
        setOffset(document.querySelector(' .container')?.offsetLeft)
    }, [])
    const subtitle = data?.section_data?.subtitle;
    const brands = data?.posts?.list?.[0]?.images;
    return (
        <StyledComponent offset={offset} className={'pt-150 pb-150'}>
            <Container>
                <Row className={'management-team'}>
                    <Col md={12}>
                        <div className="navigation_wrapper">
                            <div>
                                <Subtitle margin={'0 0 40px 0'} text={subtitle ? subtitle : 'Fostering innovation <span>together</span>'}/>
                            </div>
                            <ul className="navigation-btn">
                                    <NextBtn prevId={'prev_brand'} nextId={'next_brand'}/>
                                </ul>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Container fluid className={'p-0 about-section__bottom'} >
                <Row>
                    <Col>
                        {
                            brands && brands?.length>0 &&
                            <Swiper
                                slidesPerView={4}
                                spaceBetween={30}
                                autoplay={true}
                                // slidesPerGroup={2}
                                speed='500'
                                navigation={{
                                    prevEl: '#prev_brand',
                                    nextEl: '#next_brand',
                                }}
                                loop={false}
                                breakpoints={{
                                    320: {
                                        slidesPerView: 2,
                                        spaceBetween: 30,

                                    },
                                    1025: {
                                        slidesPerView: 4,
                                        spaceBetween: 30,
                                    },
                                }}
                                modules={[Autoplay, Pagination, Navigation]}

                                className="mySwiper"
                            >
                                {
                                    brands && brands.length>0 &&
                                    brands?.map((element,index)=>{
                                        return(
                                            <SwiperSlide key={index}>
                                                <div className="testimonial__single">
                                                    <div className="testimonial__single__img">
                                                        <Img src={element?.full_path}/>
                                                        <p>{element?.short_title}</p>
                                                    </div>

                                                </div>
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper>
                        }
                    </Col>
                </Row>
            </Container>

        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    
    .navigation_wrapper{
        display: flex;
        justify-content: space-between;
        @media(max-width: 767px){
            flex-direction: column;
            .navigation-btn{
                margin-bottom: 40px;
            }
        }
    }
    .about-section__bottom {

        position: relative;

        &::after {
            position: absolute;
            content: '';
            background: #2B3944;
            height: 50%;
            width: 100%;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }
    .management-team{
        margin-bottom: 60px;
        @media(max-width: 767px){
            margin-bottom: 0;
        }
    }
    .testimonial__single{
        overflow: hidden;
        &__img{
            position: relative;
            padding-top: calc(230 / 270 * 100%);
            //border: 0.5px solid #CBB1B1;
            overflow: hidden;
            box-shadow: 0 10px 10px 0px rgba(0, 0, 0, 0.05);
            background: white;
            img{
                width: 180px;
                height: 180px;
                object-fit: contain;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                opacity: 1;
                transition: 0.5s ease-in-out;

            }
            p{
                text-align: center;
                top: 60%;
                left: 50%;
                transform: translate(-50%, -50%);
                position: absolute;
                opacity: 0;
                transition: 0.5s ease-in-out;
            }
            @media(max-width: 767px){
            }
            &:hover{
                img{
                    opacity: 0;
                    top: 40%;
                }
                p{
                    opacity: 1;
                    top: 50%;
                }
            }
        }
        
    }

    .testimonial__head__navigation{
        .navigation{
            justify-content: end;
            margin-top: 30px;
            @media(max-width: 767px){
                margin-top: 0;
            }
        }
        @media(max-width: 767px){
            display: none;
        }
    }
    .mobile-btn{
        display: none;
        margin-top: 40px;
        @media(max-width: 767px){
            display: block;
        }
    }
    .container-fluid{
        padding-left: ${props => props.offset ? props.offset +15+'px !important' : ''};
        @media (max-width: 767px) {
            padding-left: 15px !important;
        }
    }
    .swiper-initialized {
        padding-right: ${props => props.offset ? props.offset +15+'px !important' : ''};

        @media (min-width: 1500px) {
            padding-right: 140px;

        }
        @media (max-width: 767px) {
            padding-right: 15px !important;
        }
    }
    .swiper-slide{
        //width: 270px !important;
    }
`;

export default MyComponent;
