import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {ImageParallax} from "./ImageParallax";
import Title from "./Title";
import {Col, Container, Row} from "react-bootstrap";
import Button from "./Button";
import {Img} from "./Img";
import reactHtmlParser from "react-html-parser";


const Cta = ({title, desktopImg, mobImg, buttonText, link, bg}) => {



    return (

        <StyledCTA className={'pt-160 pb-160'} bg={bg}>
            <Container className="image-wrapper">
                {
                    window.innerWidth > 768 ?
                        <ImageParallax dataSpeed={'0.8'} src={desktopImg ? desktopImg : ''}/>
                        :
                        <Img src={mobImg ? mobImg : ''}/>
                }
                    <Row>
                        <Col md={7}>
                            <div className="cta-content">
                                { title &&
                                    <h2 data-lag={0.1}>{reactHtmlParser(title)}</h2>
                                }
                                <Button hoverBackground={'#CA0034'} hoverBorderColor={'#CA0034'}
                                        data-lag={0.1}
                                        background={'transparent'} border={'1px solid #F8F8F9'}
                                        color={'#F8F8F9'} src={'/contact'}
                                        text={buttonText ? buttonText : ''}/>
                            </div>


                        </Col>
                    </Row>
            </Container>
        </StyledCTA>

    )
};


const StyledCTA = styled.section`
  position: relative;
  overflow: hidden;
  background: ${props => props.bg ? props.bg : ''} !important;
  
  .container {
    position: relative!important;
  }

  .image-wrapper {
    padding-top: 160px;
    padding-bottom: 160px;
    //padding-top: calc(606 / 1366 * 100%);
    position: relative;
    overflow: hidden;

    @media(min-width: 1920px){
      padding-top: 250px;
      padding-bottom: 250px;
    }
    h2 {
      color: #F8F8F9;
      padding-bottom: 40px;
      display: flex;
      flex-wrap: wrap;
    }
  }




  /* Tablet desktop :768px. */
  @media (min-width: 768px) and (max-width: 991px) {
    h2 {
      font-size: 36px;
      font-weight: 500;
      letter-spacing: -2.16px;
      line-height: 40px;
    }
  }

  @media (max-width: 767px) {
    .image-wrapper {
      padding-top: 80px;
      padding-bottom: 80px;
    }

  }
    
    .cta-content{
        padding-left: 85px;
        position: relative;
        z-index: 5;
        @media (max-width: 767px) {
            padding-left: 0;
        }
    }
`;

export default React.memo(Cta);














