export const ApiParam = {
    PAGE_ID: 'page_id',
    PAGE_SLUG: 'page_slug',
    VALUE: 'value',
    GET_SECTION: 'get_section',
    IMAGE: 'image',
    POST: 'post',
    FILE: 'file',
    GALLERY: 'gallery',
    TYPE : 'type',
    DIVISION : 'division',
    DISTRICT : 'district',
};
