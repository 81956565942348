import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import VideoModal from "../VideoModal";
import AccordionItem from "../Accordion";

const VideoFeatures = ({ offset, data }) => {
  const video = data?.images?.list?.[0];
  const accordion = data?.posts?.list;

  return (
    <StyledComponent offset={offset} className={"pt-160"}>
      <Container className={"p-0"} fluid>
        <Row>
          <Col md={12} lg={6}>
            <VideoModal video={video} />
          </Col>
          <Col md={12} lg={{ offset: 1, span: 5 }}>
            <div className="accordion-feature">
              <AccordionItem accordion={accordion} />
            </div>
          </Col>
        </Row>
      </Container>
    </StyledComponent>
  );
};

const StyledComponent = styled.section`
  background-color: #2b3944;
  padding-right: ${(props) => (props.offset ? props.offset : "")}px;

  /* Tablet desktop :768px. */
  @media (min-width: 768px) and (max-width: 991px) {
    padding-right: unset;
    .accordion-feature {
      padding: 40px 15px 120px 15px;
    }
    .offset-sm-1 {
      margin-left: unset;
    }
  }
  /* small mobile :320px. */
  @media (max-width: 767px) {
    padding-right: unset;
    .accordion-feature {
      padding: 40px 15px 120px 15px;
    }
  }
`;

export default React.memo(VideoFeatures);
