import React from 'react';
import styled from 'styled-components';
import {hover} from "../styles/globalStyleVars";
import {Link} from "react-router-dom";


const ButtonIcon = ({
                        onSubmit,
                        text,
                        src,
                        img,
                        hoverImg,
                        fontSize,
                        fontWeight,
                        color,
                        letterSpacing,
                        lineHeight,
                        margin,
                        marginMobile,
                        background,
                        borderRadius,
                        border,
                        width,
                        height,
                        hoverBackground,
                        target,
                        borderColor,
                        hoverColor,
                        hoverBorderColor
                    }) => {


    return (
        <StyledBtn className={`dc-btn fade-up`}
                   fontSize={fontSize}
                   fontWeight={fontWeight}
                   color={color}
                   background={background}
                   lineHeight={lineHeight}
                   letterSpacing={letterSpacing}
                   margin={margin}
                   marginMobile={marginMobile}
                   border={border}
                   img={img}
                   borderRadius={borderRadius}
                   width={width}
                   hoverImg={hoverImg}
                   hoverBackground={hoverBackground}
                   height={height}
                   borderColor={borderColor}
                   target={target}
                   hoverColor={hoverColor}
                   hoverBorderColor={hoverBorderColor}
                   onSubmit={onSubmit}
        >

            {src ?
                <Link to={src}>
                    <span> {text}  </span>
                </Link>
                :
                <Link to={'/'} onClick={e => e.preventDefault()}>
                    <span><svg xmlns="http://www.w3.org/2000/svg" width="15.495" height="16" viewBox="0 0 15.495 16">
  <path id="Path_8987" data-name="Path 8987"
        d="M39.244,23.7h3.024a.509.509,0,0,1,.508.508v11.1a.509.509,0,0,1-.508.508H39.244a.509.509,0,0,1-.508-.508v-11.1a.509.509,0,0,1,.508-.508Zm-6.813-2.236-.6-.434a.169.169,0,0,1,.048-.3l2.816-.9a.169.169,0,0,1,.221.15l.215,3.1a.169.169,0,0,1-.268.149l-.542-.393c-2.087,2.875-4.205,3.549-6.889,3.254a.169.169,0,0,1-.028-.331A8.475,8.475,0,0,0,32.431,21.461Zm-4.213,8.567h3.024a.509.509,0,0,1,.508.508v4.773a.509.509,0,0,1-.508.508H28.217a.509.509,0,0,1-.508-.508V30.536a.509.509,0,0,1,.508-.508Zm8.537-3.165H33.731a.509.509,0,0,0-.508.508v7.939a.509.509,0,0,0,.508.508h3.024a.509.509,0,0,0,.508-.508V27.371A.509.509,0,0,0,36.755,26.863Z"
        transform="translate(-27.281 -19.818)" fill="#f8f8f9" fill-rule="evenodd"/>
</svg>
                        {text}  </span>
                </Link>
            }


        </StyledBtn>
    )
};

const StyledBtn = styled.div`
    &.dc-btn {
        margin: ${props => props.margin || '0'};
        width: ${props => props.width || 'fit-content'};
        height: ${props => props.height || '40'}px;
        cursor: pointer;

        a {
            display: flex;
            height: 100%;
            width: 100%;
            align-items: center;
            justify-content: center;

            margin: 0;
            line-height: ${props => props.lineHeight || '20'}px;
            background-color: ${props => props.background || hover};
            position: relative;
            border-radius: ${props => props.borderRadius || '24'}px;
            overflow: hidden;
            z-index: 0;
            transition: border .3s ease;
            padding: 12px 36px;
            box-sizing: border-box;
            border: ${p => p.border};

            span {
                transition: color .3s ease;
                color: ${props => props.color || `#FFF`};
                position: relative;
                z-index: 2;
                width: max-content;
                font-size: ${props => props.fontSize || '16'}px;
                font-weight: ${props => props.fontWeight || 400};
                display: flex;
                gap: 8px;
            }


            &:before {
                top: 105%;
                content: "";
                display: block;
                position: absolute;
                right: 0;
                left: 0;
                background-color: ${p => p.hoverBackground || '#404040'};
                height: 100%;
                width: 100%;
                margin: auto;
                transition: all .5s ease;
                border-radius: 24px;
            }

            &:hover {
                span {
                    color: ${props => props.hoverColor || `#FFF`};
                }


                &:before {
                    top: 0
                }

                border-color: ${p => p.hoverBorderColor};
            }

            &:focus {
                color: #222222;
            }
        }

        @media (max-width: 767px) {
            margin: ${p => p.marginMobile};
        }
    }



`;


export default React.memo(ButtonIcon);
