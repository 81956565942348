import React from 'react';
import styled from "styled-components";
import {Container} from "react-bootstrap";
import Cta from "./Cta";

const MyComponent = ({bigImg, mobileImg, title, bgColor, buttonText, pb, redirect}) => {
    return (
        <StyledComponent bgColor={bgColor} className={'pt-160 pb-160'}>
            <Container>
                <Cta redirect={redirect} bigImg={bigImg} mobileImg={mobileImg} title={title} buttonText={buttonText} pb={pb} />
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: ${props => props.bgColor ? props.bgColor : ''};
  overflow: hidden;
  /* small mobile :320px. */
  @media (max-width: 767px) {
    .container {
      padding-left: 0px;
      padding-right: 0px;
    }

  }
`;

export default React.memo(MyComponent);
