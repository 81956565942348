import React, {useRef, useState} from "react";
import styled from "styled-components";
import {Row, Col, Form} from "react-bootstrap";
import Button from "./Button";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {apiEndPoints} from "../api/network/apiEndPoints";
import {postForm} from "../api/redux/contact";
import Select, {components} from "react-select";

const MyComponent = () => {
    const dispatch = useDispatch();
    const responseData = useSelector((state) => state.contact);

    const customStyles = {
        dropdownIndicator: (base, state) => ({
            ...base,
            transition: 'all .2s cubic-bezier(.74,0,.24,.99)',
            transform: state.selectProps.menuIsOpen && 'rotate(180deg)'
        }),
        option: (provided, state) => ({
            ...provided,
            borderRadius: 0,
            color: state.isSelected ? "#FFFFFF" : "#CA0034",
            backgroundColor: state.isSelected ? "#CA0034" : "#FFFFFF",
            margin: 0,
            fontSize: 16,
            cursor: 'pointer',
            lineHeight: '20px',
            paddingLeft: 10,
            paddingRight: 10,
            fontWeight: state.isSelected ? 400 : 400,
            borderBottom: state.options.indexOf(state.data) === state.options.length - 1 ? 'none' : '1px solid #888888', // Check if it's the last item

            '&:hover': {
                backgroundColor: "#CA0034",
                color: "#FFFFFF",
                cursor: 'pointer'
            },

        }), menu: (provided, state) => ({
            ...provided,
            color: '#888888',
            backgroundColor: state.isSelected ? "#FFFFFF" : "#FFFFFF",
            margin: '15px 0 0 0',
            border: '1px solid #CA0034',
            padding: 0,
            borderRadius: 10,
            fontSize: 12,
            zIndex: 10,
            borderBottom: '1px solid #888888'

            // width: 200,
        }), menuList: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "#FFFFFF" : "#FFFFFF",
            borderRadius: 0,
            fontWeight: '400',
            color: '#FFF',
            fontSize: 12,
        }),

    };
    const {register, handleSubmit, formState, reset} = useForm({mode: "all"});

  const optionsOne = [
    {value: 'autogas', label: 'AutoGas '},
    {value: 'residential', label: 'Commercial '},
    {value: 'industrial', label: 'Industrial '},
  ];
  const [customerType, setCustomerType] = useState('')
  const customerRef = useRef(null);
  const handleCustomerType = (e) => {
    setCustomerType(e)
  }
  const clearSelectValue = () => {
    if (customerRef.current) {
      customerRef?.current?.clearValue();
    }
  };
  const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
          <CaretDownIcon/>
        </components.DropdownIndicator>
    );
  };
  const CaretDownIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12.828" height="7.828" viewBox="0 0 12.828 7.828">
          <g id="Arrow" transform="translate(17238.414 7077.914)">
            <line id="Line_3886" data-name="Line 3886" x2="5" y2="5" transform="translate(-17237 -7076.5)" fill="none" stroke="#FFF" strokeLinecap="round" strokeWidth="2"/>
            <line id="Line_3887" data-name="Line 3887" x1="5" y2="5" transform="translate(-17232 -7076.5)" fill="none" stroke="#FFF" strokeLinecap="round" strokeWidth="2"/>
          </g>
        </svg>

        );
    };
    //--- form submit
    const success = (msg) =>
        toast.success(msg, {
            position: "top-right",
            autoClose: 4000,
            closeOnClick: true,
            progress: undefined,
        });

    const error = (msg) =>
        toast.error(msg, {
            position: "top-right",
            autoClose: 4000,
            closeOnClick: true,
            progress: undefined,
        });

    const onSubmit = async (e) => {
        let api_services = apiEndPoints?.FORM_SUBMIT;

        var formData = new FormData();
        formData.append("company_name", e?.company_name);
        formData.append("address", e?.address);
        formData.append("number", e?.number);
        formData.append("service_type", customerType);
        formData.append("email", e?.email);
        formData.append("division", e?.division);
        formData.append("upazila", e?.upazila);
        formData.append("form_id", "usp-form");

        if (e.company_name !== "" && e.address !== "" && e.number !== "") {
            await dispatch(postForm([api_services, formData]));
            success("Thank you for contacting with us!");
            reset();
            clearSelectValue();
        }
    };

    let count = 0;
    const onError = (errors) => {
        Object.values(errors).forEach((error) => {
            count++;
        });
        if (count > 0) {
            toast.error("please fill out the correct inputs");
        }
        count = 0;
    };
    return (
        <StyledComponent>
            <Form>
                {/*<Row>*/}
                {/*  <Col md={6} xs={6}>*/}
                {/*    <div className="input-item">*/}
                {/*      <Form.Control type="text" placeholder="Filled Field" />*/}
                {/*      <Form.Control type="text" placeholder="Unfilled Field *" />*/}
                {/*      <Form.Control type="text" placeholder="Unfilled Field *" />*/}
                {/*      <Form.Control type="text" placeholder="Unfilled Field *" />*/}
                {/*      <Form.Control type="text" placeholder="Unfilled Field *" />*/}
                {/*    </div>*/}
                {/*  </Col>*/}
                {/*  <Col md={6} xs={6}>*/}
                {/*    <div className="input-item">*/}
                {/*      <Form.Control type="text" placeholder="Unfilled Field *" />*/}
                {/*      <Form.Control type="text" placeholder="Unfilled Field *" />*/}
                {/*      <Form.Control type="text" placeholder="Unfilled Field *" />*/}
                {/*      <Form.Control type="text" placeholder="Unfilled Field *" />*/}
                {/*      <Form.Control type="text" placeholder="Unfilled Field *" />*/}
                {/*    </div>*/}
                {/*  </Col>*/}
                {/*</Row>*/}
                <Row>
                    <Col sm={12}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Control
                                className={
                                    formState?.errors?.company_name?.message
                                        ? "has-error form-control-lg"
                                        : "form-control-lg"
                                }
                                {...register("company_name", {
                                    required: "Name is required",
                                    pattern: {
                                        value: /([A-Za-z])\w+/,
                                        message: "Name must contain only letters",
                                    },
                                })}
                                type="text"
                                placeholder="Company Name*"
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Control
                                className={
                                    formState?.errors?.email?.message
                                        ? "has-error form-control-lg"
                                        : "form-control-lg"
                                }
                                {...register("email", {
                                    required: {
                                        value: true,
                                        message: "please enter email",
                                    },
                                })}
                                type="text"
                                placeholder="Email*"
                            />
                            <Form.Control
                                className={
                                    formState?.errors?.address?.message
                                        ? "has-error form-control-lg"
                                        : "form-control-lg"
                                }
                                {...register("address", {
                                    required: {
                                        value: true,
                                        message: "please enter your address",
                                    },
                                })}
                                type="text"
                                placeholder="Address*"
                            />
                        </Form.Group>

                    </Col>

                    <Col sm={6}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Control
                                className={
                                    formState?.errors?.number?.message
                                        ? "has-error form-control-lg"
                                        : "form-control-lg"
                                }
                                {...register("number", {
                                    required: {
                                        value: true,
                                        message: "please enter your phone first",
                                    },
                                    pattern: {
                                        value: /^01[0-9]{9}$/,
                                        message: "please enter a valid 11 digit phone number",
                                    },
                                })}
                                type="number"
                                placeholder="Phone Number*"
                            />
                        </Form.Group>
                        <Form.Group className={'from-group'}>
                            <Select onChange={(e) => handleCustomerType(e?.value)} components={{DropdownIndicator}}
                                    styles={customStyles}
                                    ref={customerRef}
                                    classNamePrefix={'custom'} className='select-here files'
                                    placeholder={'Service Type'}
                                    options={optionsOne}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Control
                            className={
                                formState?.errors?.division?.message
                                    ? "has-error form-control-lg"
                                    : "form-control-lg"
                            }
                            {...register("division", {
                                required: {
                                    value: true,
                                    message: "please enter division",
                                },
                            })}
                            type="text"
                            placeholder="Division*"
                        />
                    </Col>
                    <Col>
                        <Form.Control
                            className={
                                formState?.errors?.upazila?.message
                                    ? "has-error form-control-lg"
                                    : "form-control-lg"
                            }
                            {...register("upazila", {
                                required: {
                                    value: true,
                                    message: "please enter upazila",
                                },
                            })}
                            type="text"
                            placeholder="Upazila*"
                        />
                    </Col>
                </Row>
                <div className="button">
                    <div className="button-click" onClick={handleSubmit(onSubmit, onError)}>
                        <Button
                            src
                            fontSize={"16"}
                            text={"Submit Message"}
                            border={"1px solid #F8F8F9"}
                            background={"transparent"}
                            hoverBorderColor={"#CA0034"}
                            hoverBackground={"#CA0034"}
                        />
                    </div>

                </div>
            </Form>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  padding-top: 60px;

  input {
    background-color: transparent;
    color: #f8f8f9;
    opacity: 0.5;
    border: none;
    border-bottom: 1px solid rgba(248, 248, 249, 0.9);
    padding-bottom: 10px;
    padding-left: 0px;

    &:focus {
      background-color: transparent;
      color: #f8f8f9;
      opacity: 1;
      border: none;
      border-bottom: 1px solid rgba(248, 248, 249);
      padding-bottom: 10px;
    }
  }

  .input-item {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .button {
    padding-top: 60px;
    display: flex;
    justify-content: center;
  }

  .css-13cymwt-control {
    border-color: transparent;
  }

  .custom__control {
    background-color: transparent;
    border: none;
    border-radius: 0px;
    padding-top: 10px;

    .custom__value-container {
      padding-left: 0;
    }

    &:focus {
      background-color: transparent;
      color: #f8f8f9;
      opacity: 1;
      border: none !important;
      outline-color: transparent;
      border-bottom: 1px solid rgba(248, 248, 249);
      padding-bottom: 10px;
    }

    input {
      &::placeholder {
        background-color: transparent;
        color: rgb(248, 248, 249);
        opacity: 0.5;
        font-size: 20px;
      }
    }

    .custom__input-container {
      &::placeholder {
        color: red;
      }
    }

    .custom__input {
      &::placeholder {
        color: red !important;
      }
    }
  }

  .css-b62m3t-container {
    border-bottom: 1px solid rgba(248, 248, 249, 0.5);

    &:focus-visible {
      outline: none;
      border: none;
      border-bottom: 1px solid rgba(248, 248, 249, 0.5);
    }

    &:focus {
      outline: none;
      border: none;
      border-bottom: 1px solid rgba(248, 248, 249, 0.5);
    }
  }

  .css-1dimb5e-singleValue {
    color: rgba(248, 248, 249, 0.5) !important;
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
  }

  .css-qbdosj-Input {
    color: rgba(248, 248, 249, 0.5) !important;
  }

  .css-1jqq78o-placeholder {
    color: rgba(248, 248, 249, 0.2) !important;
    font-size: 20px;
    font-weight: 300;
    line-height: 20px;
  }

  .from-group {
    &:focus-visible {
      outline: 0 !important;
    }
  }

  .custom__control--is--focused {
    outline: 0 !important;
    border: none;
  }

  .css-t3ipsp-control {
    border: none !important;

    &:hover {
      border: none !important;
    }

    &:focus {
      border: none !important;
    }

    &:focus-visible {
      border: none !important;
    }
  }
`;

export default React.memo(MyComponent);
