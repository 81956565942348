import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { HelmetProvider, Helmet } from "react-helmet-async";
import InnerBanner from "../../components/InnerBanner";
import MediaList from "../../components/media/MediaList";
import { useDispatch, useSelector } from "react-redux";
import { apiEndPoints } from "../../api/network/apiEndPoints";
import { ApiParam } from "../../api/network/apiParams";
import { fetchMediaData, fetchMediaDetail } from "../../api/redux/media";
import transition from "../../transition";
import { motion } from "framer-motion";
import { PageAnimation } from "../../components/PageAnimation";

const MediaCenter = () => {
  const [activeCategory, setActiveCategory] = useState("All"); // Default active category is 'All'
  const [isLoading, setIsLoading] = useState(true);
  const dispath = useDispatch();

  // api call
  useEffect(() => {
    let api_url = apiEndPoints.SECTIONS;

    let api_params = {
      [ApiParam.TYPE]: "slug",
      [ApiParam.VALUE]: "media-center",
      [ApiParam.GET_SECTION]: "yes",
      [ApiParam.IMAGE]: "yes",
      [ApiParam.POST]: "yes",
    };

    dispath(fetchMediaData([api_url, api_params]));
  }, [PageAnimation]);

  // api call for media detail
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        let api_url = apiEndPoints.CHILD_PAGE;
        let api_params = {
          [ApiParam.PAGE_ID]: "15",
          [ApiParam.IMAGE]: "yes",
          [ApiParam.POST]: "yes",
          [ApiParam.FILE]: "yes",
          [ApiParam.GALLERY]: "yes",
          [ApiParam.TYPE]: activeCategory, // Use the value property of activeCategory
        };
        await dispath(fetchMediaDetail([api_url, api_params]));
        setIsLoading(false);
      } catch (err) {
        // Handle errors if needed
        console.error("Error fetching products:", err);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispath, activeCategory]);

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
  };

  const getData = useSelector((state) => state.media);
  const innerContent = getData?.posts?.data?.page_data;
  const innerImage = getData?.posts?.data?.page_images?.list?.[0];
  const detail = getData?.detail?.data?.list;
  const category = getData?.detail?.data?.alltype;

  const newData = detail?.filter((item) =>
    item?.page_data?.type === activeCategory
      ? item?.page_data?.type === activeCategory
      : detail
  );

  // Check if originalArray is defined and is an array
  const selectedCategory = Array.isArray(category)
    ? category.map((item) => ({
        value: item.slug,
        label: item.title,
      }))
    : [];

  return (
    <HelmetProvider>
      <motion.div
        key={`589`}
        className="page-loader"
        exit="exit"
        animate="anim"
        variants={PageAnimation}
        initial="init"
      ></motion.div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Omera Gas One | Media Center</title>
        <meta
          name="Discover the latest news, updates, and multimedia resources in our dynamic Media Center"
          content="Stay informed with Omera"
        />
      </Helmet>

      <StyledComponent>
        {/*{isLoading && <div className={'loader'}><span className={'spinner'}></span></div>}*/}
        <InnerBanner
          subtitle={innerContent?.short_desc}
          breadcrumbs={innerContent?.breadcumb}
          title={innerContent?.subtitle}
          img={innerImage?.full_path}
        />
        <MediaList
          selectedCategory={selectedCategory}
          activeCategory={activeCategory}
          handleCategoryClick={handleCategoryClick}
          category={category}
          data={newData}
        />
      </StyledComponent>
    </HelmetProvider>
  );
};

const StyledComponent = styled.div`
  position: relative;
`;

export default memo(MediaCenter);
