import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import ReactHtmlParser from "react-html-parser";

const Overview = ({description, aboutPage, title, id}) => {
    return (
        <StyledComponent className={"about-global divider pt-160 pb-160"} id={id}>
            <Container>
                <Row>
                    <Col sm={aboutPage ? 11 : 9}>
                        {title && <h4>{title}</h4>}
                        {description && <p>{ReactHtmlParser(description)}</p>}
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  background-color: #2b3944;

  .global-image {
    background-image: none !important;
  }

  h4 {
    font-size: 36px;
    line-height: 40px;
    color: #ffffff;
    font-weight: 400;
    margin-bottom: 50px;
    display: flex;
    flex-wrap: wrap;
  }

  p {
    color: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-wrap: wrap;
    font-size: 18px;
    
    &:not(:nth-last-of-type(1)) {
      margin-bottom: 24px;
    }
  }

  .mission-vision {
    margin-top: 80px;

    h3 {
      color: #ffffff;
      font-size: 20px;
      line-height: 28px;
      font-weight: 500;
      margin-bottom: 20px;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    h4 {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 40px;
    }

    h4 p {
      font-size: 30px !important;
      line-height: 30px !important;
    }

    .mission-vision {
      margin-top: 40px;

      p {
        margin-bottom: 40px;
      }
    }
  }

  @media (max-width: 767px) {
    h4 {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 40px;
    }

    h4 p {
      font-size: 30px !important;
      line-height: 30px !important;
    }

    .mission-vision {
      margin-top: 40px;

      p {
        margin-bottom: 40px;
      }
    }
  }
`;

export default React.memo(Overview);
