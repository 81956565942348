import React from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {
    console.log(data)
    const subtitle = data?.subtitle;
    const short_desc = data?.short_desc;
    return (
        <StyledComponent className={'divider'}>
            <>
                <div className={'shadow-behind'} data-speed={0.9}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="367.646" height="584" viewBox="0 0 367.646 584">
                        <path id="Path_2143" data-name="Path 2143"
                              d="M526.223,3.725C494.653,191.217,328.494,158.65,221.47,235.077c-86.644,61.787-37.784,280.272-37.784,280.272,3.745-27.507,21.193-49.139,102.879-92.043,30.136-16.014,69.057-34.6,119.39-57.783,86.069-39.438,118.275-119.57,128-194.4C545.424,83.9,526.223,3.725,526.223,3.725"
                              transform="translate(-169.96 -3.725)" opacity="0.2" fill="#9cabb9"/>
                        <path id="Path_2145" data-name="Path 2145"
                              d="M385.453,17.986c-18.5,109.929-115.945,90.848-178.688,135.6-50.612,36.272-21.989,164.327-21.989,164.327,2.17-16.233,12.288-28.862,60.252-54.078,17.688-9.282,40.434-20.2,69.895-33.821C365.3,206.932,384.238,160,389.973,116.084c6.653-51.17-4.52-98.1-4.52-98.1"
                              transform="translate(-42.19 266.083)" opacity="0.2" fill="#9cabb9"/>
                    </svg>

                </div>
                <Container>
                    <Row>
                        <Col md={12}>
                            <h4>{subtitle}</h4>
                            {
                                short_desc &&
                                <h1 className={'split-up'}> {reactHtmlParser(short_desc)} </h1>
                            }
                        </Col>
                    </Row>
                </Container>
            </>
        </StyledComponent>
    );
};
const StyledComponent = styled.section`
    position: relative;
    overflow: hidden;
    margin-top: 100px;


    .shadow-behind {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        svg {
            width: 90%;
        }
    }

    h4 {
        margin-bottom: 40px;
    }

    h1 {
        color: #2B3944;
        overflow: hidden;
        line-height: 90px;
        letter-spacing: -0.8px;

        span {
            color: #CA0034;
            font-size: 80px;
            line-height: 90px;
            font-weight: 500;
        }

        @media (max-width: 1365px) and (min-width: 991px) {
            font-size: 60px;
            line-height: 72px;

            span {
                color: #CA0034;
                font-size: 60px;
                line-height: 72px;
            }
        }

        @media (max-width: 767px) {
            font-size: 40px;
            line-height: 48px;

            span {
                color: #CA0034;
                font-size: 40px;
                line-height: 48px;
            }
        }
    }


    @media (max-width: 1920px) {
        padding-top: 200px;
        padding-bottom: 200px;
    }
    @media (max-width: 767px) {
        padding-top: 160px;
        padding-bottom: 160px;
        margin-top: unset !important;
        .shadow-behind {
            svg {
                width: 80%;
            }
        }
    }
`
export default React.memo(MyComponent);
